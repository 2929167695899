
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Divider } from 'primereact/divider';
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import { Paginator } from "primereact/paginator";
import { addDateTime } from "../../../common/date-time";
import "./GAAzureVmAlloyCreateJob.scss";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import SelectedVmDetails from "./SelectedVmDetails";
import {
    getVmListInit,
    postAlloyVmCreateJobInit,
    resetCloneJobDetails,
    resetCreateJobSuccessMessage,
    resetModifyJobDetails,
} from "../redux/actions";
import { Chip } from "primereact/chip";
import { Dialog } from "primereact/dialog";
const GAAzureVmAlloyCreateJob = () => {
    const modifyJobDetails = useSelector(
        (state) => state.gaAzureVmAlloy.setModifyJobDetails
    );
    const cloneJobDetails = useSelector(
        (state) => state.gaAzureVmAlloy.setCloneJobDetails
    );
    const history = useHistory();
    const toastRef = useRef();
    const createJobBtnRef = useRef(null);
   
    const [firstTimeRendering, setFirstTimeRendering] = useState(false);
    const [isInitialized, setisInitialized] = useState(false);
    
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [selectedCommand, setSelectedCommand] = useState("Start");
    const [ritNumber, setRitNumber] = useState("");
    const [forceUpdate] = useState(false);
    const [selectedJobInfo, setSelectedJobInfo] = useState(null);
    const [showExistingJobInfoDialog, setShowExistingJobInfoDialog] =
        useState(false);
    const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
    const [jobPayload, setJobPayload] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [jobType, setJobType] = useState(null);
    const [isModelContainerExpanded, setIsModelContainerExpanded] =
        useState(true);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const storeRowRefs = useRef({});
    
    
    const dispatch = useDispatch();
    

// starting Updated part
//state management
const vmData = useSelector((state) => state.gaAzureVmAlloy.vmData); // Updated selector for VM data
const createVmAlloyJobSuccessMessage = useSelector(
  (state) => state.gaAzureVmAlloy.createVmAlloyJobSuccessMessage
);
const [multiselectLabel, setMultiselectLabel] = useState("Portfolio"); // Label for MultiSelect
    const [options, setOptions] = useState([]); // Options for MultiSelect
    const [selectedValues, setSelectedValues] = useState([]); // Selected values for MultiSelect
const [filteredVms, setFilteredVms] = useState([]);
const [selectedVms, setSelectedVms] = useState([]); // for dialog
const [selectedVmNames, setSelectedVmNames] = useState([]); // For payload
const[enteredVmName,setEnteredVmName] = useState("");
const [showSelectedVmDialog, setShowSelectedVmDialog] = useState(false);

const labelOptions = [
  { label: "Portfolio", value: "Portfolio" },
  { label: "App Code", value: "App Code" },
];
// fetch VM data on mount
useEffect(() => {
  dispatch(getVmListInit());
}, [dispatch]);

useEffect(() => {
  if (vmData) {
      console.log('VmData', vmData?.data); // Optional chaining to avoid errors
      console.log('VmDatalist', vmData);
      console.log('createVmAlloyJobSuccessMessage', createVmAlloyJobSuccessMessage);
  }
}, [vmData, createVmAlloyJobSuccessMessage]);

useEffect(() => {
  if(!firstTimeRendering && vmData?.data?.length)
  {
    setFirstTimeRendering(true);

  }
},[vmData, firstTimeRendering]);

// Populate MultiSelect options based on label
useEffect(() => {
  if (!isInitialized &&  vmData?.data?.length) {
      if (multiselectLabel === "Portfolio") {
          const uniquePortfolios = [...new Set(vmData.data.map((item) => item.portfolio))]
              .map((portfolio) => ({ label: portfolio, value: portfolio }));
          setOptions(uniquePortfolios);
          setSelectedValues(uniquePortfolios.map((item) => item.value)); // Default to all Portfolios selected
      } else if (multiselectLabel === "App Code") {
          const uniqueAppCodes = [...new Set(vmData.data.map((item) => item.appCode))]
              .map((code) => ({ label: code, value: code }));
          setOptions(uniqueAppCodes);
          setSelectedValues(uniqueAppCodes.map((item) => item.value)); // Default to all App Codes selected
      }
  }
}, [vmData, multiselectLabel]);

// Filter VMs based on selected values
useEffect(() => {
  if (selectedValues.length > 0 && vmData?.data?.length) {
      const relatedVms = vmData.data.filter((item) =>
          selectedValues.includes(multiselectLabel === "Portfolio" ? item.portfolio : item.appCode)
      );
      // Update `isSelected` only if selectedVms has values
      const updatedFilteredVms = relatedVms.map((vm) => ({
        ...vm,
        isSelected: selectedVms.length > 0 && selectedVms.includes(vm.vmName),
    }));

    setFilteredVms(updatedFilteredVms);
  } else {
      setFilteredVms([]);
  }
}, [selectedValues, vmData, multiselectLabel]);


const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
};
// Toggle VM selection
const toggleVmSelection = (vmName) => {
    const updated = filteredVms.map((vm) =>
        vm.vmName === vmName ? { ...vm, isSelected: !vm.isSelected } : vm
    );
    setFilteredVms(updated);
    setSelectedVmNames(updated.filter((vm) => vm.isSelected).map((vm) => vm.vmName));
    setSelectedVms(updated.filter((vm) => vm.isSelected));
};
// Select All VMs
const handleSelectAllVms = () => {
    const updated = filteredVms.map((vm) => ({ ...vm, isSelected: true }));
    setFilteredVms(updated);
    setSelectedVmNames(updated.map((vm) => vm.vmName));
    setSelectedVms(updated);
};
// Reset Selection
const handleResetSelection = () => {
    const updated = filteredVms.map((vm) => ({ ...vm, isSelected: false }));
    setFilteredVms(updated);
    setSelectedVms([]);
    setSelectedVmNames([]);
};



    // Search and scroll to a specific VM
    const handleSearchVM = () => {
      scrollToShowEnteredVm();
  };

  const scrollToShowEnteredVm = () => {
      let enteredVmIndex = 0;
      if (enteredVmName) {
          filteredVms.forEach((vm, index) => {
              if (vm.vmName === enteredVmName) {
                  enteredVmIndex = index;
              }
          });
          setFirst(enteredVmIndex);
      }
  };

  const handleSearchInputChange = (e) => {
      setEnteredVmName(e.target.value);
  };

  const handleKeyDown = (e) => {
      if (e.key === "Enter") {
          handleSearchVM();
      }
  };


     
    useEffect(() => {
      if (firstTimeRendering && !modifyJobDetails && !cloneJobDetails) {
        resetSelections();  
       
      } 
      if (!isInitialized&&firstTimeRendering && modifyJobDetails && vmData?.data?.length) {
        initializeModifyJobDetails(modifyJobDetails); 
      setisInitialized(true);
      
      } 
      if (firstTimeRendering && !!cloneJobDetails) {
        initializeCloneJobDetails(cloneJobDetails);
        setisInitialized(true);
      }
  }, [firstTimeRendering, modifyJobDetails, cloneJobDetails, dispatch]);

    const navigateToJobDetails = () => {
        setTimeout(() => {
            history.push("/admin/grafana-agent/azure-vm-alloy/job-details");
        }, 3000);
    };
    

    // Initialize modify details
    const initializeModifyJobDetails = (details) => {
      console.log("inside create job",details)

      const {
          jobId,
          portfolios=[],
          appCodes=[],
          vmNames=[],
          command,
          scheduledDateTime,
          jobType,
          ritNumber,
      } = details;

      setJobId(jobId);
      setSelectedCommand(command);
      setSelectedDateTime(scheduledDateTime ? new Date(scheduledDateTime) : null);
      setJobType(jobType);
      setRitNumber(ritNumber);
      console.log("portfolios",portfolios);

      if (appCodes.length > 0 && appCodes[0] !== "") {
        setMultiselectLabel("App Code");

        const appCodeOptions = vmData?.data
            ?.map((vm) => vm.appCode)
            ?.filter((value, index, self) => self.indexOf(value) === index)
            .map((appCode) => ({ label: appCode, value: appCode })) || [];

        setOptions(appCodeOptions);

        const validAppCodes = appCodes.filter((appCode) =>
            appCodeOptions.some((option) => option.value === appCode)
        );

        setSelectedValues(validAppCodes);
    } else if (portfolios.length > 0  && portfolios[0] !== "" ) {
        setMultiselectLabel("Portfolio");

        const portfolioOptions = vmData?.data
            ?.map((vm) => vm.portfolio)
            ?.filter((value, index, self) => self.indexOf(value) === index)
            .map((portfolio) => ({ label: portfolio, value: portfolio })) || [];

        setOptions(portfolioOptions);
        setSelectedValues(portfolios);
    }
     

// Filter VMs based on exact portfolio or appCode match
if (vmData?.data?.length) {
  let filtered = vmData.data;
  if (portfolios.length > 0 && portfolios[0] !== "") {
      filtered = filtered.filter((vm) =>
          portfolios.some((portfolio) => vm.portfolio ===   portfolio)
      );
  } else if (appCodes.length > 0 && appCodes[0] !== "") {
      filtered = filtered.filter((vm) =>
        appCodes.some((code) => vm.appCode === code)
      );
  }

  // Update filteredVms with exact match and selection based on vmNames
  const updatedFilteredVms = filtered.map((vm) => ({
      ...vm,
      isSelected: vmNames.some((name) => name === vm.vmName),
  }));
  setFilteredVms(updatedFilteredVms);

  // Set selectedVms based on updatedFilteredVms
  const updatedSelectedVms = updatedFilteredVms
      .filter((vm) => vm.isSelected)
      .map((vm) => vm.vmName);
  setSelectedVms(updatedSelectedVms);

  console.log("Updated Filtered VMs:", updatedFilteredVms);
  console.log("Updated Selected VMs:", updatedSelectedVms);
} else {
  console.warn("VM Data is empty or not loaded.");
  setFilteredVms([]);
  setSelectedVms([]);
}

  };

  // Initialize clone details
  const initializeCloneJobDetails = (details) => {
      const { portfolio=[], appCode=[], vms=[], command } = details;

      setSelectedCommand(command);
      if (portfolio && portfolio.length) {
          setMultiselectLabel("Portfolio");
          setSelectedValues(portfolio);
      } else if (appCode && appCode.length) {
          setMultiselectLabel("App Code");
          setSelectedValues(appCode);
      }

      setFilteredVms(
          vmData.data.map((vm) => ({
              ...vm,
              isSelected: vms.includes(vm.vmName),
          }))
      );
      setSelectedVms(vms);
  };

    
    useEffect(() => {
        if (createVmAlloyJobSuccessMessage) {
          console.log("createVmAlloyJobSuccessMessage",createVmAlloyJobSuccessMessage)
            if (
                createVmAlloyJobSuccessMessage.status==200 && (createVmAlloyJobSuccessMessage.message==    "Created scheduled job successfully")
                
            ) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Job created successfully.",
                    life: 3000,
                });
                dispatch(resetCreateJobSuccessMessage());
                dispatch(resetCloneJobDetails());
                resetSelections();
                navigateToJobDetails();
            } else if (
                createVmAlloyJobSuccessMessage.status==200 && createVmAlloyJobSuccessMessage.data=="Modified job successfully"
            ) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Job modified successfully.",
                    life: 3000,
                });
                dispatch(resetCreateJobSuccessMessage());
                dispatch(resetModifyJobDetails());
                resetSelections();
                navigateToJobDetails();
            } else if (
                createVmAlloyJobSuccessMessage.status==200 && createVmAlloyJobSuccessMessage.message=="Job Excecution started"
            ) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Job execution started .",
                    life: 3000,
                });
                dispatch(resetCreateJobSuccessMessage());
                dispatch(resetCloneJobDetails());
                resetSelections();
                navigateToJobDetails();
            } else if (createVmAlloyJobSuccessMessage.status==200 && createVmAlloyJobSuccessMessage.message=="Job Accepted") {
                toastRef.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Job Accepted.",
                    life: 3000,
                });
                dispatch(resetCreateJobSuccessMessage());
                dispatch(resetCloneJobDetails());
                resetSelections();
                navigateToJobDetails();
            } else { if(createVmAlloyJobSuccessMessage.status==422 && Array.isArray(createVmAlloyJobSuccessMessage.data)){
              console.log("createVmAlloyJobSuccessMessage.data",createVmAlloyJobSuccessMessage.data)  
              // const jobIds = createVmAlloyJobSuccessMessage.data.map(
              //       (job) => (job.data.jobData.match(/job-(\d+)/) || [])[0]
              //   );
                 // Safely map jobIds
        const jobIds = createVmAlloyJobSuccessMessage.data
        .filter((job) => job?.jobData) // Ensure `jobData` exists
        .map((job) => {
            const match = job.jobData.match(/job-(\d+)/);
            return match ? match[0] : null; // Extract jobId or return null
        })
        .filter(Boolean); // Remove any `null` values
                confirmPopup({
                    target: createJobBtnRef.current,
                    message: (
                        <div>
                            <div>Job already exists.</div>
                            {jobIds.length > 0 && (
                                <div>
                                    Job Ids:{" "}
                                    {jobIds.map((jobId, index) => (
                                        <React.Fragment key={jobId}>
                                            <a
                                                href={`#${jobId}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const selectedJob =
                                                        createVmAlloyJobSuccessMessage.data.find(
                                                            (job) =>
                                                                job.jobData.includes(
                                                                    jobId
                                                                )
                                                        );
                                                    setSelectedJobInfo(
                                                        selectedJob
                                                    );
                                                    setShowExistingJobInfoDialog(
                                                        true
                                                    );
                                                    setShowConfirmPopUp(false);
                                                }}
                                            >
                                                {jobId}
                                            </a>
                                            {index < jobIds.length - 1
                                                ? ", "
                                                : ""}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                            <div>Do you still wish to continue?</div>
                        </div>
                    ),
                    accept: () => {
                        dispatch(
                            postAlloyVmCreateJobInit({
                                ...jobPayload,
                                forceUpdate: true,
                            })
                        );
                        dispatch(resetCreateJobSuccessMessage());
                        setShowConfirmPopUp(false);
                    },
                    reject: () => {
                        resetSelections();
                        dispatch(resetCreateJobSuccessMessage());
                        dispatch(resetModifyJobDetails());
                        dispatch(resetCloneJobDetails());
                        setShowConfirmPopUp(false);
                    },
                });}
            }
        }
    }, [createVmAlloyJobSuccessMessage, dispatch]);

 
    // Reset selections to default
    const resetSelections = () => {
      setSelectedCommand(null);
      setSelectedDateTime(null);
      setJobType(null);
      setRitNumber("");
      setSelectedValues([]);
      setFilteredVms(
          filteredVms.map((vm) => ({ ...vm, isSelected: false }))
      );
      setSelectedVms([]);
      setSelectedVmNames([]);
      setMultiselectLabel("Portfolio");
  };
   
  const handleVmAlloyCreateJob = async () => {
    const validationChecks = [
        {
            condition: !selectedValues.length,
            message: "Please select Portfolio or AppCode.",
        },
        {
            condition: !selectedVms.length && !selectedVmNames.length,
            message: "Please select at least one VM.",
        },
        {
            condition: !selectedCommand,
            message: "Please select a command.",
        },
        {
            condition: !jobType,
            message: "Please choose a Job Type.",
        },
        {
            condition: selectedCommand !="Get Status" && !ritNumber,
            message: "Please enter RIT/INC/Change Number.",
        },
    ];

    for (const check of validationChecks) {
        if (check.condition) {
            toastRef.current.show({
                severity: "error",
                summary: "Error",
                detail: check.message,
                life: 3000,
            });
            return;
        }
    }

    if (jobType === "Execute") {
        setSelectedDateTime(null);
    }

    // Determine portfolio and appCode based on selectedValues
    let selectedPortfolios = [];
    let selectedAppCodes = [];
    console.log("selectedVms in create job", selectedVmNames, selectedVms);

    if (multiselectLabel === "Portfolio") {
        selectedPortfolios = [
            ...new Set(
                filteredVms
                    .filter((vm) =>
                        selectedVms.some((selected) =>
                            typeof selected === "string"
                                ? selected === vm.vmName
                                : selected.vmName === vm.vmName
                        )
                    )
                    .map((vm) => vm.portfolio)
            ),
        ];
        selectedAppCodes = []; // Set appCode to empty
    } else if (multiselectLabel === "App Code") {
        selectedAppCodes = [
            ...new Set(
                filteredVms
                    .filter((vm) =>
                        selectedVms.some((selected) =>
                            typeof selected === "string"
                                ? selected === vm.vmName
                                : selected.vmName === vm.vmName
                        )
                    )
                    .map((vm) => vm.appCode)
            ),
        ];
        selectedPortfolios = []; // Set portfolio to empty
    }

    // Normalize selectedVms for payload
    const normalizedSelectedVms =
        selectedVmNames.length > 0
            ? selectedVmNames
            : selectedVms.map((vm) =>
                  typeof vm === "string" ? vm : vm.vmName
              );

    const payload = {
        jobId: jobId,
        portfolio: selectedPortfolios.length ? selectedPortfolios : [],
        appCode: selectedAppCodes.length ? selectedAppCodes : [],
        vms: normalizedSelectedVms,
        command: selectedCommand,
        scheduleDateTime: selectedDateTime
            ? selectedDateTime.toISOString()
            : null,
        forceUpdate: forceUpdate,
        jobType: jobType,
        incNumber: ritNumber,
    };

    console.log("Generated Payload:", payload);

    dispatch(postAlloyVmCreateJobInit(payload));
      console.log("createVmAlloyJobSuccessMessage", createVmAlloyJobSuccessMessage);

    setJobPayload(payload);
};

    const handleDialogClose = () => {
        setShowExistingJobInfoDialog(false);
        setShowConfirmPopUp(true);
    };
    
    const handleJobTypeChange = (jobType) => {
        setJobType(jobType);
        if (jobType === "Execute") {
            setSelectedDateTime(null);
        }
    };
    const handleToggleModelContainer = () => {
        setIsModelContainerExpanded(!isModelContainerExpanded);
    };

   const getVmClass = (vm) => {
        if (vm.isSelected) {
            return "vm-btn selected"; // For selected VMs
        } else if (enteredVmName && vm.vmName.includes(enteredVmName)) {
            return "vm-btn highlighted-vm"; // For searched VMs
        } else {
            return "vm-btn default"; // Default state
        }
    };
      

    // Disable conditions
    const isModifyMode = !!modifyJobDetails; // True if modifyJobDetails exists
    const isActionDisabled = selectedValues.length === 0 || selectedVms.length === 0;
    const isJobTypeDisabled =
        selectedValues.length === 0 ||
        selectedVms.length === 0 ||
        !selectedCommand;
    const isRITNumberDisabled =
        selectedValues.length === 0 ||
        selectedVms.length === 0 ||
        !selectedCommand ||
        !jobType;
    const isCreateJobDisabled =
        selectedValues.length === 0 ||
        selectedVms.length === 0 ||
        !selectedCommand ||
        !jobType ||
       (selectedCommand!=="Get Status" && !ritNumber);
       
       const handleShowSelectedVmDetails = () => {
          setShowSelectedVmDialog(true);
      }; 

    return (
      <div className="create-job-container">
        <Dialog
          visible={showExistingJobInfoDialog}
          onHide={handleDialogClose}
          style={{ width: "50vw" }}
          header={`${selectedJobInfo?.jobData}`}
        >
          {selectedJobInfo && (
            <div className="server-info-container">
              {selectedJobInfo?.serverData.map((serverEntry) => (
                <div key={serverEntry} className="server-entry">
                  {serverEntry}{" "}
                </div>
              ))}
            </div>
          )}
        </Dialog>
        <div className="appcode-dropdown-container">
          <div className="division-dropdown">
          <Dropdown
                        options={labelOptions}
                        value={multiselectLabel}
                        onChange={(e) => setMultiselectLabel(e.value)}
                        placeholder="Select Label"
                        disabled={isModifyMode}
                    />
            <MultiSelect
                        options={options}
                        value={selectedValues}
                        onChange={(e) => setSelectedValues(e.value)}
                        placeholder={`Select ${multiselectLabel}`}
                        filter
                        filterBy="label"
                        style={{ width: '325px'}}
                        disabled={isModifyMode}
                    />
          </div>
        </div>
        <div
          className={`grafana-agent-command-main ${
            isModelContainerExpanded ? "" : "collapsed"
          }`}
        >
          <div className="vm-container">
            {filteredVms && (
            <div className="selected-vms">
                <strong>Selected VM: </strong>
                <span>
                  {filteredVms
                  .filter((vm)=>vm.isSelected)
                  .slice(0, 2).map((vm) => (
                    <Chip
                      key={vm.vmName}
                      label={vm.vmName}
                      style={{ margin: "0.5rem" }}
                    />
                  ))}
                </span>
                {filteredVms && filteredVms.filter((vm)=>vm.isSelected).length >= 3 && (
              <>
                <Tooltip target=".more" />
                <Chip
                  label="..."
                  className="more"
                  data-pr-tooltip="Detailed View"
                  data-pr-position="right"
                  onClick={handleShowSelectedVmDetails}
                />
              </>
            )}
            </div>
            )}
            <div className="vm-container-top">
              <div className="update-vm-selection">
                <div className="vm-select-search-container">
                <Button
                  label="Select All VM"
                  onClick={handleSelectAllVms}
                />
                <Button
                  label="Reset"
                  onClick={handleResetSelection}
                />
                </div>
                <div className="search-vm">
                  <InputText
                    type="text"
                    placeholder="Search VM"
                    // value={searchTerm}
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    value={enteredVmName}
                        onChange={handleSearchInputChange}
                        onKeyDown={handleKeyDown}
                  />
                  <Button
                    label="Search"
                    icon="pi pi-search"
                    onClick={handleSearchVM}
                    className="filter-button p-button-outlined p-button-sm"
                  />
                </div>
              </div>
            </div>
            <div className="vm-list">
              <div className="vm-grid">
            {filteredVms &&
              filteredVms.slice(first, first + rows).map((vm) => (
                <Button
                  key={vm.vmName}
                  ref={(ref) => (storeRowRefs.current[vm.vmName] = ref)}
                  label={
                    <div>
                      <span>{vm.vmName}</span>
                    </div>
                  }
                  //className="store-btn"
                  //className={vm.isSelected ? "vm-btn selected" : "vm-btn default"}
                  className={getVmClass(vm)}
                  onClick={() => toggleVmSelection(vm.vmName)}
                  
                />
              ))}
              </div>
              </div>
            <Paginator
              first={first}
              rows={rows}
              onPageChange={onPageChange}
              totalRecords={filteredVms.length}
              rowsPerPageOptions={[10, 20, 50]}
            />
          </div>
          <Divider layout="vertical"/>
          <div
            className={`model-container ${
              isModelContainerExpanded ? "expanded" : "collapsed"
            }`}
          >
            <div className="service-dropdown">
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                Action
              </span>
              <Dropdown
                optionLabel="action"
                optionValue="action"
                options={[
                  { action: "Start" },
                  { action: "Stop" },
                  { action: "Restart" },
                  { action: "Get Status" },
                ]}
                value={selectedCommand}
                style={{ width: "70%" }}
                onChange={(event) => {
                  setSelectedCommand(event.value);
                }}
                disabled={isActionDisabled}
              />
            </div>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "52%" }}>Choose Job Type :</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <RadioButton
                      inputId="executeNow"
                      name="executionOption"
                      value="Execute"
                      disabled={isJobTypeDisabled || isModifyMode}
                      onChange={() => handleJobTypeChange("Execute")}
                      checked={jobType === "Execute"}
                    />
                    <label htmlFor="executeNow">Execute Now</label>
                  </td>
                  <td>
                    <RadioButton
                      inputId="schedule"
                      name="executionOption"
                      value="Scheduled"
                      disabled={isJobTypeDisabled || isModifyMode}
                      onChange={(e) => setJobType(e.value)}
                      checked={jobType === "Scheduled"}
                    />
                    <label htmlFor="schedule">Schedule Later</label>
                  </td>
                </tr>
              </tbody>
            </table>
            {jobType === "Scheduled" && (
                          <div className="service-dropdown">
                          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                            Schedule Datetime
                          </span>
                          <Calendar
                                    // id="time24"
                                    testid="date-time-picker"
                                    minDate={addDateTime("minutes", 10, new Date())}
                                    value={selectedDateTime}
                                    // className="division-dropdown"
                                    onChange={(e) => setSelectedDateTime(e.value)}
                                    style={{ paddingTop: "0.5rem" }}
                                    disabled={isJobTypeDisabled}
                                    showIcon
                                    showTime
                                  />
                        </div>
            )}
            <div>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                RIT/INC/Change Number{" "}
              </span>
              <>
              {selectedCommand !== "Get Status" && (
      <>
        <Tooltip target=".rit-info-tooltip" />
        <span
          className="rit-info-tooltip"
          style={{ cursor: "pointer", color: "red" }}
          data-pr-tooltip="RIT/INC/CHG should be created to perform actions on the VMs."
          data-pr-position="right"
        >
          *
        </span>
      </>
    )}
              
              </>
              <InputText
                value={ritNumber}
                onChange={(e) => setRitNumber(e.target.value)}
                disabled={isRITNumberDisabled || isModifyMode}
              />
            </div>
            {filteredVms && (
              <Button
                label={!modifyJobDetails ? "Create Job" : "Modify Job"}
                className="submit-btn"
                ref={createJobBtnRef}
                onClick={(event) =>
                  confirmPopup({
                    target: event.currentTarget,
                    icon: "pi pi-info-circle",
                    message: !modifyJobDetails
                      ? "Do you want to create this job?"
                      : "Do you want to modify this job?",
                    accept: () => handleVmAlloyCreateJob(),
                  })
                }
                disabled={isCreateJobDisabled}
              />
            )}
          </div>
          <Button
            icon={`pi pi-arrow-${isModelContainerExpanded ? "right" : "left"}`}
            className="collapse-button"
            onClick={handleToggleModelContainer}
          ></Button>
        </div>
        <ConfirmPopup
          visible={showConfirmPopUp}
          onHide={() => setShowConfirmPopUp(false)}
        />
        <ConfirmDialog />
        <Toast ref={toastRef} position="bottom-center" />
        <SelectedVmDetails
                visible={showSelectedVmDialog}
                onHide={() => setShowSelectedVmDialog(false)}
                selectedVms={selectedVms}
            />

      </div>
    );
};
export default GAAzureVmAlloyCreateJob;
