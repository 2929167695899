import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
 
const SelectedVmDetails = ({ visible, onHide, selectedVms }) => {
    const [tableFilters, setTableFilters] = useState(null);
    const [rows, setRows] = useState(5);
    const [first, setFirst] = useState(0);
    const tableRef = useRef(null);
 console.log('selectedVms from dialog',selectedVms);
    // Initialize filters
    const initializeFilters = () => {
        setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            appCode: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            portfolio: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            vmName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            computerName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
        });
    };
 
    // Initialize filters on component mount
    React.useEffect(() => {
        initializeFilters();
    }, []);
 
    const exportTable = () => {
        console.log('exportTable',exportTable);
        if(tableRef.current)
            {
                tableRef.current.exportCSV();
            }
        };
    return (
<Dialog
            visible={visible}
            onHide={onHide}
            header="Selected VM Details"
            style={{ width: "70vw" }}
>
<div>

</div>
<DataTable
                ref={tableRef}
                value={selectedVms}
                paginator
                rows={rows}
                rowsPerPageOptions={[5, 10, 20]}
                totalRecords={selectedVms.length}
                first={first}
                onPage={(e) =>{ setFirst(e.first);
                    setRows(e.rows);
                }}
                filters={tableFilters}
                filterDisplay="menu"
                responsiveLayout="scroll"
                emptyMessage="No VMs selected."
>
<Column field="appCode" header="App Code" filter sortable />
<Column field="portfolio" header="Portfolio" filter sortable />
<Column field="vmName" header="VM Name" filter sortable />
<Column field="computerName" header="Computer Name" filter sortable />
</DataTable>
</Dialog>
    );
};
 
export default SelectedVmDetails;