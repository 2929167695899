export const GET_GA_REMOTE_COMMAND_DIVISION_DETAILS = {
  INIT: "GET_GA_REMOTE_COMMAND_DIVISION_DETAILS_INIT",
  SUCCESS: "GET_GA_REMOTE_COMMAND_DIVISION_DETAILS_SUCCESS",
  ERROR: "GET_GA_REMOTE_COMMAND_DIVISION_DETAILS_ERROR",
};



export const GET_VM_LIST = {
  INIT: "GET_VM_LIST_INIT",
  SUCCESS: "GET_VM_LIST_SUCCESS",
  ERROR: "GET_VM_LIST_FAILURE",
};


export const POST_CREATE_JOB = {
  INIT: "POST_CREATE_JOB_INIT",
  SUCCESS: "POST_CREATE_JOB_SUCCESS",
  ERROR: "POST_CREATE_JOB_ERROR",
};

export const POST_CREATE_VM_ALLOY_JOB = {
  INIT: "POST_CREATE_VM_ALLOY_JOB_INIT",
  SUCCESS: "POST_CREATE_VM_ALLOY_JOB_SUCCESS",
  ERROR: "POST_CREATE_VM_ALLOY_JOB_ERROR",
};

export const POST_EXECUTE_NOW_JOB = {
  INIT: "POST_EXECUTE_NOW_JOB_INIT",
  SUCCESS: "POST_EXECUTE_NOW_JOB_SUCCESS",
  ERROR: "POST_EXECUTE_NOW_JOB_ERROR",
};

export const GET_JOB_DETAILS = {
  INIT: "GET_JOB_DETAILS_INIT",
  SUCCESS: "GET_JOB_DETAILS_SUCCESS",
  ERROR: "GET_JOB_DETAILS_ERROR",
};

export const GET_ALLOY_VM_JOB_DETAILS = {
  INIT: "GET_ALLOY_VM_JOB_DETAILS_INIT",
  SUCCESS: "GET_ALLOY_VM_JOB_DETAILS_SUCCESS",
  ERROR: "GET_ALLOY_VM_JOB_DETAILS_ERROR",
};

export const RESET_CREATE_JOB_SUCCESS_MESSAGE =
  "RESET_CREATE_JOB_SUCCESS_MESSAGE";

export const RESET_EXECUTE_NOW_JOB_SUCCESS_MESSAGE =
  "RESET_EXECUTE_NOW_JOB_SUCCESS_MESSAGE";

export const GET_SERVER_DETAILS_BY_JOB_ID = {
  INIT: "GET_SERVER_DETAILS_BY_JOB_ID_INIT",
  SUCCESS: "GET_SERVER_DETAILS_BY_JOB_ID_SUCCESS",
  ERROR: "GET_SERVER_DETAILS_BY_JOB_ID_ERROR",
};

export const GET_ALLOY_VM_DETAILS_BY_JOB_ID = {
  INIT: "GET__ALLOY_VM__DETAILS_BY_JOB_ID_INIT",
  SUCCESS: "GET__ALLOY_VM__DETAILS_BY_JOB_ID_SUCCESS",
  ERROR: "GET__ALLOY_VM__DETAILS_BY_JOB_ID_ERROR",
};

export const CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE = {
  INIT: "CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE_INIT",
  SUCCESS: "CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE_SUCCESS",
  ERROR: "CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE_ERROR",
};

export const CANCEL_ALLOY_VM_COMMAND_SCHEDULE = {
  INIT: "CANCEL_ALLOY_VM_COMMAND_SCHEDULE_INIT",
  SUCCESS: "CANCEL_ALLOY_VM_COMMAND_SCHEDULE_SUCCESS",
  ERROR: "CANCEL_ALLOY_VM_COMMAND_SCHEDULE_ERROR",
};

export const SET_MODIFY_JOB_DETAILS = "SET_MODIFY_JOB_DETAILS";

export const RESET_MODIFY_JOB_DETAILS = "RESET_MODIFY_JOB_DETAILS";

export const SET_CLONE_JOB_DETAILS = "SET_CLONE_JOB_DETAILS";

export const RESET_CLONE_JOB_DETAILS = "RESET_CLONE_JOB_DETAILS";

export const SET_SORTING = 'SET_SORTING';
export const SET_FILTERS = 'SET_FILTERS';
