import {
  GET_GA_REMOTE_COMMAND_DIVISION_DETAILS,
  POST_CREATE_JOB,
  GET_JOB_DETAILS,
  RESET_CREATE_JOB_SUCCESS_MESSAGE,
  RESET_MODIFY_JOB_DETAILS,
  GET_SERVER_DETAILS_BY_JOB_ID,
  GET_ALLOY_VM_DETAILS_BY_JOB_ID,
  CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE,
  CANCEL_ALLOY_VM_COMMAND_SCHEDULE,
  SET_MODIFY_JOB_DETAILS,
  SET_CLONE_JOB_DETAILS,
  RESET_CLONE_JOB_DETAILS,
  GET_VM_LIST,
  POST_CREATE_VM_ALLOY_JOB,
  GET_ALLOY_VM_JOB_DETAILS,

} from "./actionTypes";

export const getGaRemoteCommandDivisionDetailsInit = () => ({
  type: GET_GA_REMOTE_COMMAND_DIVISION_DETAILS.INIT,
});

export const getGaRemoteCommandDivisionDetailsSuccess = (divisionDetail) => ({
  type: GET_GA_REMOTE_COMMAND_DIVISION_DETAILS.SUCCESS,
  payload: divisionDetail,
});

export const getGaRemoteCommandDivisionDetailsFailure = (errorMessage) => ({
  type: GET_GA_REMOTE_COMMAND_DIVISION_DETAILS.ERROR,
  payload: errorMessage,
});

export const getVmListInit = () => ({
  type: GET_VM_LIST.INIT
});

export const getVmListSuccess = (vmList) => ({
  type: GET_VM_LIST.SUCCESS,
  payload: vmList,
});

export const getVmListFailure = (error) => ({
  type: GET_VM_LIST.ERROR,
  payload: error,
});



export const postCreateJobInit = (payload) => ({
  type: POST_CREATE_JOB.INIT,
  payload,
});

export const postCreateJobSuccess = (createJob) => ({
  type: POST_CREATE_JOB.SUCCESS,
  payload: createJob,
});

export const postCreateJobFailure = (errorMessage) => ({
  type: POST_CREATE_JOB.ERROR,
  payload: errorMessage,
});


export const postAlloyVmCreateJobInit = (payload) => ({
  type: POST_CREATE_VM_ALLOY_JOB.INIT,
  payload,
});

export const postAlloyVmCreateJobSuccess = (createJob) => ({
  type: POST_CREATE_VM_ALLOY_JOB.SUCCESS,
  payload: createJob,
});

export const postAlloyVmCreateJobFailure = (errorMessage) => ({
  type: POST_CREATE_VM_ALLOY_JOB.ERROR,
  payload: errorMessage,
});


export const getJobDetailsInit = (startDate, endDate, offset) => ({
  type: GET_JOB_DETAILS.INIT,
  startDate,
  endDate,
  offset,
});

export const getJobDetailsSuccess = (jobDetails) => ({
  type: GET_JOB_DETAILS.SUCCESS,
  payload: jobDetails,
});

export const getJobDetailsFailure = (errorMessage) => ({
  type: GET_JOB_DETAILS.ERROR,
  payload: errorMessage,
});


export const getAlloyVmJobDetailsInit = (startDate, endDate, offset,pageNumber = 1, pageSize =10,
  sortBy = "createdDateTime",               // Default sorting field
  sortDirection = "desc",              // Default sort direction
  filterCondition = null                // Default filter condition
) => ({
  type: GET_ALLOY_VM_JOB_DETAILS.INIT,
  startDate,
  endDate,
  offset,
  pageNumber,
  pageSize,
  sortBy,                             // Added sorting field
  sortDirection,                      // Added sorting direction
  filterCondition                     // Added filter condition
});

export const getAlloyVmJobDetailsSuccess = (jobDetails) => ({
  type: GET_ALLOY_VM_JOB_DETAILS.SUCCESS,
  payload: jobDetails,
});

export const getAlloyVmJobDetailsFailure = (errorMessage) => ({
  type: GET_ALLOY_VM_JOB_DETAILS.ERROR,
  payload: errorMessage,
});



export const resetCreateJobSuccessMessage = () => ({
  type: RESET_CREATE_JOB_SUCCESS_MESSAGE,
});


export const resetModifyJobDetails = () => ({
  type: RESET_MODIFY_JOB_DETAILS,
});

export const getServerDetailsByJobIdInit = (jobId) => ({
  type: GET_SERVER_DETAILS_BY_JOB_ID.INIT,
  jobId,
});

export const getServerDetailsByJobIdSuccess = (serverDetails) => ({
  type: GET_SERVER_DETAILS_BY_JOB_ID.SUCCESS,
  payload: serverDetails,
});

export const getServerDetailsByJobIdFailure = (errorMessage) => ({
  type: GET_SERVER_DETAILS_BY_JOB_ID.ERROR,
  payload: errorMessage,
});

//Alloy vm

export const getAlloyVmDetailsByJobIdInit = (jobId) => ({
  type: GET_ALLOY_VM_DETAILS_BY_JOB_ID.INIT,
  jobId,
});

export const getAlloyVmDetailsByJobIdSuccess = (serverDetails) => ({
  type: GET_ALLOY_VM_DETAILS_BY_JOB_ID.SUCCESS,
  payload: serverDetails,
});

export const getAlloyVmDetailsByJobIdFailure = (errorMessage) => ({
  type: GET_ALLOY_VM_DETAILS_BY_JOB_ID.ERROR,
  payload: errorMessage,
});


//Alloy vm



export const cancelGrafanaAgentRemoteCommandScheduleInit = (
  cancelSchedule,
  startDate,
  endDate,
  offset
) => ({
  type: CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE.INIT,
  cancelSchedule,
  startDate,
  endDate,
  offset,
});

export const cancelGrafanaAgentRemoteCommandScheduleSuccess = (response) => ({
  type: CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE.SUCCESS,
  payload: response,
});

export const cancelGrafanaAgentRemoteCommandScheduleFailure = (
  errorMessage
) => ({
  type: CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE.ERROR,
  payload: errorMessage,
});

//AlloyVm


export const cancelAlloyVmRemoteCommandScheduleInit = (
  cancelSchedule,
  startDate,
  endDate,
  offset
) => ({
  type: CANCEL_ALLOY_VM_COMMAND_SCHEDULE.INIT,
  cancelSchedule,
  startDate,
  endDate,
  offset,
});

export const cancelAlloyVmRemoteCommandScheduleSuccess = (response) => ({
  type: CANCEL_ALLOY_VM_COMMAND_SCHEDULE.SUCCESS,
  payload: response,
});

export const cancelAlloyVmRemoteCommandScheduleFailure = (
  errorMessage
) => ({
  type: CANCEL_ALLOY_VM_COMMAND_SCHEDULE.ERROR,
  payload: errorMessage,
});

//AlloyVm


export const setModifyJobDetails = (modifyJobDetails) => ({
  type: SET_MODIFY_JOB_DETAILS,
  payload: modifyJobDetails,
});

export const setCloneJobDetails = (cloneJobDetails) => ({
  type: SET_CLONE_JOB_DETAILS,
  payload: cloneJobDetails,
});

export const resetCloneJobDetails = () => ({
  type: RESET_CLONE_JOB_DETAILS,
});
