import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FilterMatchMode, FilterOperator } from "primereact/api";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

import { Column } from "primereact/column";
import { parseDateTime } from "../../../common/date-time";

import { getAlloyVmDetailsByJobIdInit } from "../redux/actions";
import "./GAAzureVmAlloyJobDetails.scss";

const SubAzureVmJobDetails = ({ jobId }) => {
	const [tableFilters, setTableFilters] = useState(null);
	const subJobDetailsTableRef = useRef(null);
	const dispatch = useDispatch();
	const vmDetails = useSelector(
		(state) => state.gaAzureVmAlloy.vmDetailByJobId?.data
	);

	useEffect(() => {
		fetchServerDetailsByJobId();
		initializeTableFilter();
	}, []);

	const fetchServerDetailsByJobId = () => {
		dispatch(getAlloyVmDetailsByJobIdInit(jobId));
	};
	const initializeTableFilter = () => {
		setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            appCode: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            portfolio: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            vmName: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            computerName: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            status: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            message: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
	};
	const exportSubJobDetails = () => subJobDetailsTableRef.current.exportCSV();

	const executedTimeTemplate = ({ executedDateTime }) =>
		executedDateTime ? (
			parseDateTime(executedDateTime)
		) : (
			<span style={{ marginLeft: "50%" }}>-</span>
		);

	const completedTimeTemplate = ({ completedDateTime }) =>
		completedDateTime ? (
			parseDateTime(completedDateTime)
		) : (
				<span style={{ marginLeft: "50%" }}>-</span>
		);

	const messageTemplate = ({ message }) => {
		return (
			<>
				<Tooltip target=".tooltip-container" />
				<div
					className="tooltip-container"
					data-pr-tooltip={message}
					data-pr-position="top"
				>
					<div className="ellipsis-text">{message}</div>
					{/* <div className="tooltip-text">{message}</div> */}
				</div>
			</>
		);
	};
	return (
    <div className="sub-job-details">
      <div>
        <Button
          icon="pi pi-download"
          className="p-button-text"
          onClick={exportSubJobDetails}
          style={{ float: "right", zIndex: 1 }}
        />
        <Button
          icon="pi pi-refresh"
          className="p-button-text"
          onClick={fetchServerDetailsByJobId}
          style={{ float: "right", zIndex: 1 }}
        />
      </div>
      <DataTable
        value={vmDetails}
        ref={subJobDetailsTableRef}
        sortField="appCode"
        sortOrder={1}
        className="sub-job-table"
        filters={tableFilters}
        filterDisplay="menu"
        paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Items per page"
        rows={5}
        rowsPerPageOptions={[5, 10, 15]}
        emptyMessage="No logs found"
      >
        <Column field="appCode" header="App Code" sortable filter style={ {width:"80px"} }/>
        <Column field="portfolio" header="Portfolio" sortable filter style={ {width:"80px"} } />
        <Column field="vmName" header="VM Name" sortable filter style={ {width:"80px"} }/>
        {/* <Column field="computerName" header="Computer Name" sortable filter /> */}
        <Column
          field="executedDateTime"
          header="Executed At"
          sortable
          body={executedTimeTemplate}
		  style={ {width:"80px"} }
        />

        <Column
          field="completedDateTime"
          header="Completed At"
          sortable
          body={completedTimeTemplate}
		  style={ {width:"80px"} }
        />

        <Column field="status" header="Status" sortable filter style={ {width:"80px"} } />
        <Column
          field="message"
          header="Message"
          body={messageTemplate}
          sortable
          filter
          style={{ width: "200px" }}
        />
      </DataTable>
    </div>
  );
};

export default SubAzureVmJobDetails;
