import { GET_ALL_SNOW_APPS, GET_ALL_SNOW_WRAPPER_LOGS } from './actionTypes';

export const getAllSnowAppsInit = () => ({
    type: GET_ALL_SNOW_APPS.INIT
});

export const getAllSnowAppsSuccess = snowAppList => ({
    type: GET_ALL_SNOW_APPS.SUCCESS,
    payload: snowAppList
});

export const getAllSnowAppsFailure = errorMessage => ({
    type: GET_ALL_SNOW_APPS.ERROR,
    payload: errorMessage
});

export const getAllSnowWrapperLogsInit = (appName, startDate, endDate, offset, pageNumber, pageSize,
    sortBy ,               // Default sorting field
  sortDirection ,              // Default sort direction
  filterCondition    
) => ({
    type: GET_ALL_SNOW_WRAPPER_LOGS.INIT,
    appName, 
    startDate, 
    endDate, 
    offset, 
    pageNumber, 
    pageSize,
    sortBy ,              
    sortDirection ,              
    filterCondition 
});

export const getAllSnowWrapperLogsSuccess = snowWrapperLogs => ({
    type: GET_ALL_SNOW_WRAPPER_LOGS.SUCCESS,
    payload: snowWrapperLogs
});

export const getAllSnowWrapperLogsFailure = errorMessage => ({
    type: GET_ALL_SNOW_WRAPPER_LOGS.ERROR,
    payload: errorMessage
});
