import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";

import {
    getDistinctPingprobeAssetTypeInit,
    getPingprobeInventoryInit,
    getPingprobeAssetLastPingTimeInit,
} from "../redux/actions";
import SelectedChips from "../../../common/components/selected-chips/SelectedChips";
import { parseDateTime } from "../../../common/date-time";
import PingProbeIncidentDialog from "./PingProbeIncidentDialog";
import { tableFilters } from "../constants";
import "./PingProbeInventory.scss";


/**
 * Functional Component to show PingProbe Inventory
 * @returns JSX
 */
const PingProbeInventory = () => {
    const assetTypes = useSelector((state) => state.pingprobe.assetTypes);
    const assetList = useSelector((state) => state.pingprobe.assetList);
    const totalRecords = useSelector(state => state.pingprobe.totalRecords); // Redux total records

    const pingTime = useSelector((state) => state.pingprobe.pingTime);
    const dispatch = useDispatch();
    const toastRef = useRef(null);
    const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
    const [criticalAssetList, setCriticalAssetList] = useState([]);
    const [showCriticalAssetList, setShowCriticalAssetList] = useState(false);
    const [selectedIpAddress, setSelectedIpAddress] = useState(null);
    const inventoryTableRef = useRef(null);
    const [filterCondition, setFilterCondition] = useState(""); // **Added filterCondition state**


    const [lazyParams, setLazyParams] = useState({
        first: 0, // Starting index
        rows: 30, // Default page size
        page: 0, // Current page number
        sortField: "ipAddress", // Default sort field
        sortOrder: -1, // Default sort order (-1 for descending, 1 for ascending)
    });

    /**
     * Fetching distinct asset type list
     */
    useEffect(() => {
        if (
            !assetTypes ||
            (Array.isArray(assetTypes) && assetTypes.length === 0)
        ) {
            dispatch(getDistinctPingprobeAssetTypeInit());
        }
    }, []);

    /**
     * Fetching asset detail for all asset types
     */
    useEffect(() => {
        if (Array.isArray(assetTypes)) {
            setSelectedAssetTypes([...assetTypes]);
            if (assetTypes.length > 0) {
                fetchInventoryDetail(true);
            }
        }
    }, [assetTypes]);

    /**
     * Creating list of Critical Assets
     */
    useEffect(() => {
        console.log("assetList",assetList);
        if (Array.isArray(assetList)) {
            setCriticalAssetList(
                assetList.filter((asset) =>
                    ["New", "In Progress"].includes(asset.incidentStatus)
                )
            );
        }
        dispatch(getPingprobeAssetLastPingTimeInit());
    }, [assetList]);

    /**
     * Function to load asset list
     * @param {boolean} initialLoad
     */
    const fetchInventoryDetail = (initialLoad,params=lazyParams,currentFilters=filterCondition) => {
        const sortDirection= params.sortOrder== -1?'DESC' : 'ASC';
        let selectedAssetTypeList = selectedAssetTypes.join(",");
        if (selectedAssetTypes.length === 0 && initialLoad !== true) {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: 'No Asset Types Selected', life: 2000 });
            return;
        }
        else if (initialLoad === true) {
            selectedAssetTypeList = assetTypes.join(",");
        }
        const filterConditionPayload = constructFilterParams(currentFilters);

        dispatch(getPingprobeInventoryInit(
            selectedAssetTypeList,
            params.page, // Current page number
            params.rows, // Number of rows per page
            params.sortField, // Sorting field
            sortDirection, // Sorting order
            filterConditionPayload // **Filter condition**
        ));
    };


    const constructFilterParams = (filterConditions) => {
        if (!filterConditions || typeof filterConditions !== "object") {
          return ""; // Return empty string if no filters are present
        }
      
        const queryParams = [];
      
        Object.keys(filterConditions).forEach((key) => {
          const filter = filterConditions[key];
          const constraints = filter?.constraints;
      
          if (constraints && constraints.length > 0) {
            const { value, matchMode } = constraints[0];
      
            if (value !== undefined && value !== null && value !== "") {
              queryParams.push(
                `${encodeURIComponent(key)}&${encodeURIComponent(matchMode)}&${encodeURIComponent(value)}`
              );
            }
          }
        });
      
        return queryParams.join(",");
      };

    /**
     * Function to export table data
     */
    const exportInventoryDetail = () => inventoryTableRef.current.exportCSV();

    /**
     * Function to toogle show critical asset flag
     */
    const toogleShowCriticalAssetList = () => {
        setShowCriticalAssetList((_showCriticalAsset) => !_showCriticalAsset);
    };

    const incidentTemplate = ({
        incidentNumber,
        incidentStatus,
        incidentTimestamp,
    }) => {
        const incidentContainerClassName = ["New", "In Progress"].includes(
            incidentStatus
        )
            ? "active"
            : "";
        return (
            <div className={incidentContainerClassName}>
                {incidentNumber === null ? (
                    "-"
                ) : (
                    <span>
                        {incidentNumber} <br />
                        {parseDateTime(incidentTimestamp)}
                    </span>
                )}
            </div>
        );
    };

    const incidentLogsTemplate = ({ ipAddress }) => (
        <Button
            icon="pi pi-info-circle"
            className="p-button-text p-button-secondary"
            onClick={() => setSelectedIpAddress(ipAddress)}
        />
    );

    const severityTemplate = ({severity}) => 
         severity ? 
         (severity) : 
         (<span>-</span>);
    
    const assignmentGroupTemplate = ({assignmentGroup}) => 
        assignmentGroup ? (
            assignmentGroup
        ) : (
            <span>-</span>
        );


        /**
     * Handle filtering event
     */
    const onFilter = (filters) => {
        console.log("filters",filters);
        setFilterCondition(filters);
        const updatedParams={
            ...lazyParams, 
              first: 0,
              page: 0
        };
        setLazyParams(updatedParams); // Reset to first page
        fetchInventoryDetail(false,updatedParams,filters); // Fetch filtered data
    };

    /**
     * Handle pagination event
     */
    const onPage = (event) => {
        console.log( "pageevnt",event);
        const updatedParams=
        {
            ...lazyParams,
            first: event.first, // Start index
            rows: event.rows, // Rows per page
            page: event.page // Current page number
        };
        setLazyParams(updatedParams);
        console.log( "page",lazyParams.page, // Current page number
            lazyParams.rows, // Number of rows per page
            lazyParams.sortField)
            fetchInventoryDetail(false,updatedParams); // Fetch data for the new page
    };

    /**
     * Handle sorting event
     */
    const onSort = (event) => {
       const updatedParams={
        ...lazyParams,
        sortField: event.sortField, // Sorting field
        sortOrder: event.sortOrder // Sorting order
    };
        setLazyParams(updatedParams);
        fetchInventoryDetail(false,updatedParams); // Fetch sorted data
    };


    return (
        <div className="pingprobe-inventory-container container-fluid">
            <PingProbeIncidentDialog
                selectedIpAddress={selectedIpAddress}
                setSelectedIpAddress={setSelectedIpAddress}
            />
            <p className="page-label">
                Ping Probe
                <i className="pi pi-angle-right" />
                Inventory
            </p>
            <div className="pingprobe-inventory-action row">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="assetTypes">Asset Types</label>
                    <MultiSelect
                        id="assetTypes"
                        value={selectedAssetTypes}
                        options={assetTypes}
                        onChange={(e) => setSelectedAssetTypes(e.value)}
                    />
                </div>
                <SelectedChips
                    label="Selected Asset Types"
                    selectedValues={selectedAssetTypes}
                    setSelectedValues={setSelectedAssetTypes}
                />
                <div className="col-12">
                    <Button
                        label="Get Inventory"
                        onClick={fetchInventoryDetail}
                        disabled={selectedAssetTypes.length === 0}
                    />
                </div>
            </div>
            <hr />
            <div className="pingprobe-inventory-content">
                <div className="pingprobe-inventory-table-action">
                    <Button
                        icon="pi pi-refresh"
                        className="p-button-text"
                        onClick={fetchInventoryDetail}
                    />
                    <Button
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportInventoryDetail}
                    />
                    <Button
                        icon="pi pi-exclamation-triangle"
                        className={`${
                            !!showCriticalAssetList ? "" : "p-button-text"
                        } p-button-danger`}
                        label={"" + criticalAssetList.length}
                        onClick={toogleShowCriticalAssetList}
                    />
                    <small>
                        Last pinged at{" "}
                        {!!pingTime ? parseDateTime(pingTime) : "-"}
                    </small>
                </div>
                <DataTable
                    className="pingprobe-inventory-table"
                    ref={inventoryTableRef}
                    value={
                        showCriticalAssetList ? criticalAssetList : assetList
                    }
                    filters={tableFilters}
                    filterDisplay="menu"
                   // paginator
                   onFilter={(e) => onFilter(e.filters)} // **Handle filtering event**
                    sortField={lazyParams.sortField} // Dynamic sort field
                    sortOrder={lazyParams.sortOrder} // Dynamic sort order
                    lazy
                    paginator
                    totalRecords={totalRecords} // Dynamic total records from Redux
                    first={lazyParams.first} // Start index
                    rows={lazyParams.rows} // Rows per page
                    rowsPerPageOptions={[30, 50, 100]} // Page size options
                    onPage={onPage} // Pagination handler
                    onSort={onSort} // Sorting handler
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    emptyMessage="No Asset found."
                >
                    <Column
                        header="IP Address"
                        field="ipAddress"
                        sortable
                        filter
                    />
                    <Column
                        header="Asset Type"
                        field="assetType"
                        sortable
                        filter
                    />
                    <Column
                        header="Asset Name"
                        field="assetName"
                        sortable
                        filter
                    />
                    <Column header="DNS Name" field="dnsName" sortable filter />
                    <Column
                        header="Location"
                        field="location"
                        sortable
                        filter
                    />
                    <Column
                        header="Last Incident (In 90 days)"
                        field="incidentNumber"
                        body={incidentTemplate}
                        sortable
                    />
                    <Column
                        header="Severity"
                        field="severity"
                        body={severityTemplate}
                        sortable
                        filter
                    />
                    <Column
                        header="Assignment Group"
                        field="assignmentGroup"
                        body={assignmentGroupTemplate}
                        sortable
                        filter
                    />
                    <Column header="" body={incidentLogsTemplate} />
                </DataTable>
            </div>
        </div>
    );
};

export default PingProbeInventory;
