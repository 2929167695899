import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import Sidebar from "../sidebar/Sidebar";
import { tabList } from "../../route/AppRoute";
import PageNotFound from "../../common/components/page-not-found/PageNotFound";
import "./Dashboard.scss";
import SessionModal from "../../common/components/session-timeout/SessionModal";


const Dashboard = ({ children }) => {
  const [tabsToRender, setTabsToRender] = useState([]);

  const loader = useSelector((state) => state.loader.loader);
  const userRoles = useSelector((state) => state.profile.userRoles);

  /**
   * Filter tabs based on user role claim
   */
  useEffect(() => {
    if (!userRoles) {
      return;
    }
    const filteredTabs = tabList
      .filter((tab) => userRoles.some((role) => tab.roles.includes(role)))
      .filter(
        (tab, index, self) =>
          self.findIndex((v2) => v2.key === tab.key) === index
      )
      .sort((a, b) => a.key - b.key);

    const tabs = filteredTabs.map((tab) => {
      if (tab.child.length > 0) {
        tab.child = tab.child
          .filter((subTab) =>
            userRoles.some((role) => subTab.roles.includes(role))
          )
          .filter(
            (subTab, index, self) =>
              self.findIndex((v2) => v2.key === subTab.key) === index
          )
          .sort((a, b) => a.key - b.key);
      }
      return tab;
    });
    setTabsToRender(tabs);
  }, [userRoles]);

  /**
   * Render all routes based on tabs
   * @param {Array} tabs
   */
  const renderRoutes = (tabs) => {
    return tabs.map((tab) => {
      if (tab.child && tab.child.length > 0) {
        return renderRoutes(tab.child);
      }
      return <Route path={tab.path} component={tab.component} key={tab.key} />;
    });
  };

  return (
    <div className="dashboard-container">
      <SessionModal/>
      <Sidebar routes={tabsToRender} />
      <div className="container-fluid">
        {children}
        <Switch>
          {renderRoutes(tabsToRender)}
          {loader === 0 && <Route path="*" component={PageNotFound} />}
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
