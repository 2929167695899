import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator, PrimeIcons } from "primereact/api";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";

import Moment from "moment";

import {
	parseDateTimeWithoutSecsMillis,
	addDateTime,
} from "../../../common/date-time";
import {
	getAlloyVmJobDetailsInit,
	cancelAlloyVmRemoteCommandScheduleInit,
	setModifyJobDetails,
	resetModifyJobDetails,
} from "../redux/actions";
import SubAzureVmJobDetails from "./SubAzureVmJobDetails";
import "./GAAzureVmAlloyJobDetails.scss";
import GAAzureVmAlloyJobDetailDialog from "./GAAzureVmAlloyJobDetailDialog";

const GAAzureVmAlloyJobDetails = () => {
  const [dialogData, setDialogData] = useState(null);

  const jobDetails = useSelector((state) => state.gaAzureVmAlloy.jobDetails);
  const totalRecords = useSelector((state) => state.gaAzureVmAlloy.totalRecords);
  const userRoles = useSelector((state) => state.profile.userRoles);
  const history = useHistory();
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState([
    addDateTime("day", -14),
    addDateTime("day", 0),
  ]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [tableFilters, setTableFilters] = useState(null);

  const jobDetailsTableRef = useRef(null);

  const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
  const endDate = !!dateRange[1]
    ? Moment(dateRange[1]).format("yyyy-MM-DD")
    : Moment(dateRange[0]).format("yyyy-MM-DD");
  const offset = Moment(new Date()).utcOffset();

 // State for pagination, sorting, filtering, and initial load
 const [pageNumber, setPageNumber] = useState(1);
 const [pageSize, setPageSize] = useState(10);
 const [sortBy, setSortBy] = useState("job_id");
 const [sortDirection, setSortDirection] = useState("desc");
 const [filters] = useState(null);
 const [initialLoad, setInitialLoad] = useState(true);


 // Field mapping for backend-compatible field names
 const fieldMapping = {
     jobId: "job_id",
     createdDateTime:"created_date_time",
     action: "action",
     jobType: "job_type",
     scheduledDateTime: "scheduled_date_time",
     status: "status",
 };

 const transformSortField = (field) => fieldMapping[field] || field;

  useEffect(() => {
    initializeTableFilter();
    dispatch(resetModifyJobDetails());
    fetchJobDetails(initialLoad, pageNumber, pageSize, sortBy, sortDirection, filters);
    setInitialLoad(false);
  }, [pageNumber, pageSize, sortBy, sortDirection, filters]);

  const fetchJobDetails = (
    initialLoad = false,
    currentPage = pageNumber,
    size = pageSize,
    sortField = sortBy,
    direction = sortDirection,
    filterParams = filters
) => {
    const [startDate, endDate] = dateRange.map((date) =>
        date ? date.toISOString().split("T")[0] : null
    );
    const offset = new Date().getTimezoneOffset();

     if(initialLoad){
    setSortBy("createdDateTime");
     }
    // Updated: Transform sortBy field to backend-compatible format (highlighted)
    const backendSortBy = transformSortField(sortField);

    // Updated to conditionally include filterCondition (highlighted)
//  const requestFilterCondition = filterCondition && Object.keys(filterCondition).length > 0 ? JSON.stringify(filterCondition) : null;
 const filterParamscond = constructFilterParams(filterParams);
 console.log("filterParamscond",filterParamscond);
 const finalPageNumber=initialLoad ? 1 : currentPage;
const finalPageSize= initialLoad ? 10 : size;

    dispatch(
        getAlloyVmJobDetailsInit(
            startDate,
            endDate,
            offset,
            finalPageNumber,
            finalPageSize,
            backendSortBy,
            direction,
            filterParamscond,
        )
    );
};

const constructFilterParams = (filterConditions) => {
  if (!filterConditions || typeof filterConditions !== "object") {
    return ""; // Return empty string if no filters are present
  }

  const queryParams = [];

  Object.keys(filterConditions).forEach((key) => {
    const filter = filterConditions[key];
    const constraints = filter?.constraints;

    if (constraints && constraints.length > 0) {
      const { value, matchMode } = constraints[0];

      if (value !== undefined && value !== null && value !== "") {
        const transformedKey = fieldMapping[key] || key; // Map field names if required
        queryParams.push(
          `${encodeURIComponent(transformedKey)}&${encodeURIComponent(matchMode)}&${encodeURIComponent(value)}`
        );
      }
    }
  });

  return queryParams.join(",");
};

// Sorting logic
const handleSortChange = (sortField,sortOrder) => {
  const newSortDirection = sortOrder === 1 ? "asc" : "desc";
  const newSortField= initialLoad? "createdDateTime" : sortField;
  setSortBy(newSortField);
  setSortDirection(newSortDirection);
  fetchJobDetails(false, pageNumber, pageSize, sortField, newSortDirection, filters);
};

const onFilter=(e)=>{
  setTableFilters(e.filters);
  console.log("user log",e.filters);
  fetchJobDetails(false, 1, pageSize, sortBy, sortDirection, e.filters);
};


// Pagination logic
const handlePageChange = (newPage) => {
  setPageNumber(newPage);
  fetchJobDetails(false, newPage, pageSize, sortBy, sortDirection, filters);
};

const handlePageSizeChange = (newPageSize) => {
  setPageSize(newPageSize);
  setPageNumber(1);
  fetchJobDetails(false, 1, newPageSize, sortBy, sortDirection, filters);
};

// Pagination navigation
const handleNextPage = () => {
  if (pageNumber * pageSize < totalRecords) {
      handlePageChange(pageNumber + 1);
  }
};

const handlePrevPage = () => {
  if (pageNumber > 1) {
      handlePageChange(pageNumber - 1);
  }
};

const handleFirstPage = () => {
  handlePageChange(1);
};

const handleLastPage = () => {
  const lastPage = Math.ceil(totalRecords / pageSize);
  handlePageChange(lastPage);
};

const totalPages = Math.ceil(totalRecords / pageSize);

const renderPageNumbers = () => {
  const pageButtons = [];
  const pageRange = 5; // Display 5 page numbers
  const startPage = Math.max(1, pageNumber - Math.floor(pageRange / 2));
  const endPage = Math.min(totalPages, startPage + pageRange - 1);

  for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
          <Button
              key={i}
              label={`${i}`}
              className={pageNumber === i ? "active-page" : ""}
              onClick={() => handlePageChange(i)}
              style={{
                  backgroundColor: pageNumber === i ? "#e6f7ff" : "white",
                  color: pageNumber === i ? "#1890ff" : "black",
                  border: "1px solid #d9d9d9",
                  padding: "5px 10px",
                  borderRadius: "4px",
                  cursor: "pointer",
              }}
          />
      );
  }
  return pageButtons;
};


  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      jobId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      appCodes: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      portfolios: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      vmNames: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      action: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      jobType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const actionsTemplate = (rowData) => {
    console.log("actionsTemplate rowData", rowData);
    if (!rowData) {
      return null;
    }
    const currentTime = new Date();
    const scheduledTime = new Date(rowData.scheduledDateTime);
    const isScheduledInPast = Moment(scheduledTime).isBefore(
      currentTime,
      "day"
    );

    const minsUntilScheduledTime = Moment(scheduledTime).diff(
      currentTime,
      "minutes"
    );
    //disable the action buttons if the status is not scheduled or if the scheduled time is in the past or if less than 15 minutes remaining until the scheduled time
    const isDisabled =
      rowData.status !== "Scheduled" ||
      isScheduledInPast ||
      minsUntilScheduledTime < 15;
    return (
      <div className="ga-remote-command-action-icons">
        <Button
          icon={PrimeIcons.PENCIL}
          className="p-button-text"
          disabled={isDisabled}
          onClick={() => modifyJobHandler(rowData)}
        />
        <Button
          icon={PrimeIcons.TIMES_CIRCLE}
          className="p-button-text p-button-danger"
          disabled={isDisabled}
          onClick={(event) =>
            confirmPopup({
              target: event.currentTarget,
              icon: "pi pi-info-circle",
              message: "Do you want to cancel this schedule?",
              accept: () => handleCancelClick(rowData),
            })
          }
        />

        <Button
          icon="pi pi-eye"
          className="p-button-text"
          onClick={() => setDialogData(rowData)}
        />
      </div>
    );
  };

  /**
   * Function to export table data
   */
  const exportJobDetails = () => jobDetailsTableRef.current.exportCSV();

  const rowExpansionTemplate = ({ jobId, serviceType }) => (
    <SubAzureVmJobDetails jobId={jobId} serviceType={serviceType} />
  );

  const onRowExpand = (event) => {
    let _expandedRows = {};
    _expandedRows[`${event.data.jobId}`] = true;
    setExpandedRows(_expandedRows);
  };

  const onRowCollapse = (event) => {
    setExpandedRows(null);
  };
  
  ///updated part

  const jobTypeTemplate = ({ jobType }) =>
    jobType === "Execute" ? jobType + " Now" : jobType;

  const scheduledAtTemplate = ({ scheduledDateTime, jobType }) =>
    jobType === "Scheduled" && scheduledDateTime ? (
      parseDateTimeWithoutSecsMillis(scheduledDateTime)
    ) : (
      <span style={{ marginLeft: "43%" }}>N.A</span>
    );

  const vmServicesCountTemplate = ({
    vmCount,
    appCodeCount,
    portfolioCount,
  }) => (
    <>
      VM Count-{vmCount}
      <br />
      App Code Count-{appCodeCount}
      <br />
      Portfolio Count-{portfolioCount}
    </>
  );
  const modifyJobHandler = (rowData) => {
    const modifyJobDetails = {
      appCodes: rowData.appCodes,
      portfolios: rowData.portfolios,
      vmNames: rowData.vmNames,
      command: rowData.action,
      scheduledDateTime: rowData.scheduledDateTime,
      forceUpdate: rowData.forceUpdate,
      jobId: rowData.jobId,
      jobType: rowData.jobType,
      ritNumber: rowData.snowNumber,
    };

    dispatch(setModifyJobDetails(modifyJobDetails));
    history.push("/admin/grafana-agent/azure-vm-alloy/create-job");
  };

  const handleCancelClick = (rowData) => {
    const cancelSchedule = rowData.jobId;

    dispatch(
      cancelAlloyVmRemoteCommandScheduleInit(
        cancelSchedule,
        startDate,
        endDate,
        offset
      )
    );
  };

  const progressTemplate = ({
    completedCount,
    inProgressCount,
    failedCount,
  }) => (
    <>
      Completed-{completedCount}
      {!!inProgressCount && (
        <>
          {" "}
          <br />
          In Progress-{inProgressCount}
        </>
      )}
      {!!failedCount && (
        <>
          {" "}
          <br />
          Failed-{failedCount}
        </>
      )}
    </>
  );
  return (
    <div className="ga-remote-command-job-details-container ">
      <GAAzureVmAlloyJobDetailDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
      />
      <div className="row summary-action">
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="dateRange">Events Date</label>
          <Calendar
            id="dateRange"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            minDate={addDateTime("month", -3)}
            maxDate={addDateTime("month", 0)}
            showIcon
          />
        </div>
      </div>
      <div className="row summary-action">
        <div className="col-12 ">
          <Button label="Get Details" onClick={fetchJobDetails} />
          <Button
            icon="pi pi-download"
            className="p-button-text"
            onClick={exportJobDetails}
            style={{ float: "right" }}
          />
          <Button
            icon="pi pi-refresh"
            className="p-button-text"
            onClick={fetchJobDetails}
            style={{ float: "right" }}
          />
        </div>
      </div>
      <div>
        <ConfirmPopup />
        <DataTable
          value={jobDetails || []}
          ref={jobDetailsTableRef}
          filters={tableFilters}
          filterDisplay="menu"
          emptyMessage="No logs found"
          rows={pageSize} 
          sortField={sortBy}
          sortOrder={sortDirection==="asc"? 1 : -1}
          onSort={(e) => handleSortChange(e.sortField,e.sortOrder)}
          onFilter={onFilter}
          expandedRows={expandedRows}
          onRowExpand={onRowExpand}
          onRowCollapse={onRowCollapse}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="jobId"
        >
          <Column expander style={{ width: "5em" }} />
          <Column field="jobId" header="Job ID" sortable filter />
          <Column field="action" header="Job Action" filter />

          <Column
            field="jobType"
            header="Job Type"
            body={jobTypeTemplate}
            sortable
            filter
          />

          <Column
            field="scheduledDateTime"
            header="Scheduled At"
            body={scheduledAtTemplate}
            sortable
          />
          <Column
            field="vmCount"
            header="VM Services Count"
            body={vmServicesCountTemplate}
            sortable
          />

          <Column
            field="completedCount"
            body={progressTemplate}
            header="Progress"
            sortable
          />

          <Column field="status" header="Status" sortable filter />
          {userRoles.includes("GARemoteCommand.Write") && (
            <Column header="Actions" body={actionsTemplate} />
          )}
        </DataTable>
        <div className="custom-paginator">
<div
                        className="paginator-content"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'right',
                            gap: '10px',
                        }}
>
                        {/* Items per page dropdown */}
<label style={{ marginRight: '5px' }}>Items per page</label>
<select
                            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                            value={pageSize}
                            style={{ padding: '5px', borderRadius: '4px' }}
>
<option value={10}>10</option>
<option value={30}>30</option>
<option value={50}>50</option>
<option value={100}>100</option>
</select>
 
                        {/* First Page Button */}
<Button
                            icon="pi pi-angle-double-left"
                            disabled={pageNumber === 1}
                            onClick={handleFirstPage}
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        />
 
                        {/* Previous Page Button */}
<Button
                            icon="pi pi-angle-left"
                            disabled={pageNumber === 1}
                            onClick={handlePrevPage}
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        />
 
                        {/* Page Numbers */}
                        {renderPageNumbers()}
 
                        {/* Next Page Button */}
<Button
                            icon="pi pi-angle-right"
                            disabled={pageNumber >= totalPages}
                            onClick={handleNextPage}
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        />
 
                        {/* Last Page Button */}
<Button
                            icon="pi pi-angle-double-right"
                            disabled={pageNumber >= totalPages}
                            onClick={handleLastPage}
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        />
</div>
</div>
      </div>
    </div>
  );
};

export default GAAzureVmAlloyJobDetails;
