import axios from 'axios';
import { ApiPath } from '../../common/api-path/ApiPath';

export default class SnowWrapperService {

    async getAllSnowApps() {
        const response = await axios.get(`${ApiPath.baseUrl}/apps/appnames`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getAllSnowWrapperLogs(appName, startDate, endDate, offset, pageNumber, 
        pageSize,
        sortBy ,               // Default sorting field
        sortDirection ,              // Default sort direction
        filterCondition) {
        const requestConfig = {
            params: { appName, startDate, endDate, offset, pageNumber, 
                pageSize,
                sortBy ,               // Default sorting field
                sortDirection ,              // Default sort direction
                filterCondition },
            timeout: 120000, timeoutErrorMessage: "request timeout",
        }
        const response = await axios.get(`${ApiPath.baseUrl}/apps/incident`, requestConfig);
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}