import {
  CANCEL_ALLOY_VM_COMMAND_SCHEDULE,
  GET_VM_LIST,
  POST_CREATE_VM_ALLOY_JOB,
  GET_ALLOY_VM_JOB_DETAILS,
  RESET_CREATE_JOB_SUCCESS_MESSAGE,
  RESET_MODIFY_JOB_DETAILS,
  GET_ALLOY_VM_DETAILS_BY_JOB_ID,
  SET_MODIFY_JOB_DETAILS,
  RESET_CLONE_JOB_DETAILS,
  SET_SORTING, // New action for sorting
  SET_FILTERS  // New action for filtering
} from "./actionTypes";

const initialState = {
  divisionDetails: { serverTypes: null, divisionStores: null },
  divisionDetailsErrorMessage: null,
  createVmAlloyJobSuccessMessage: null,
  createJobErrorMessage: null,
  jobDetails: [],
  jobDetailsErrorMessage: null,
  vmDetailByJobId: [],
  vmDetailByJobIdErrorMessage: null,
  cancelGrafanaAgentRemoteCommandScheduleSuccessMessage: null,
  cancelGrafanaAgentRemoteCommandScheduleErrorMessage: null,
  setModifyJobDetails: null,
  setCloneJobDetails: null,
  vmData: [],
  vmDataErrorMessage: null,
  sortBy: 'job_id', // New state for sorting field
  sortDirection: 'desc', // New state for sorting direction
  filters: {}, // New state for filters
};

const gaAzureVmAlloyReducer = (
  state = initialState,
  action = { type: "", payload: null }
) => {
  switch (action.type) {
    
      case GET_VM_LIST.SUCCESS:
      return {
        ...state,
        vmData: action.payload,
        vmDataErrorMessage: null,
      };
    case GET_VM_LIST.ERROR:
      return {
        ...state,
        vmData: null,
        vmDataErrorMessage: action.payload,
      };

    case POST_CREATE_VM_ALLOY_JOB.SUCCESS:
      return {
        ...state,
        createVmAlloyJobSuccessMessage: action.payload,
        createJobErrorMessage: null,
      };
    case POST_CREATE_VM_ALLOY_JOB.ERROR:
      return {
        ...state,
        createVmAlloyJobSuccessMessage: null,
        createJobErrorMessage: action.payload,
      };
    case RESET_CREATE_JOB_SUCCESS_MESSAGE:
      return {
        ...state,
        createVmAlloyJobSuccessMessage: null,
      };
 
    case RESET_MODIFY_JOB_DETAILS:
      return {
        ...state,
        setModifyJobDetails: null,
      };
  
    case GET_ALLOY_VM_JOB_DETAILS.SUCCESS:
      let { azureVMJobDetails, totalRecords } = action.payload.data;
 
      // Apply filters if any
      if (Object.keys(state.filters).length > 0) {
        azureVMJobDetails = azureVMJobDetails.filter((job) => {
          return Object.keys(state.filters).every((key) => {
            const filter = state.filters[key];
            const value = job[key]?.toString().toLowerCase() || "";
            return value.includes(filter?.toLowerCase() || "");
          });
        });
      }
 
      // Apply sorting if specified
      if (state.sortBy) {
        azureVMJobDetails = azureVMJobDetails.sort((a, b) => {
          if (state.sortDirection === "asc") {
            return a[state.sortBy] > b[state.sortBy] ? 1 : -1;
          } else {
            return a[state.sortBy] < b[state.sortBy] ? 1 : -1;
          }
        });
      }
 
      return {
        ...state,
        jobDetails: azureVMJobDetails,
        totalRecords:totalRecords,
        jobDetailsErrorMessage: null,
      };
    case GET_ALLOY_VM_JOB_DETAILS.ERROR:
      return {
        ...state,
        jobDetails: [],
        totalRecords:0,
        jobDetailsErrorMessage: action.payload,
      };
    case GET_ALLOY_VM_DETAILS_BY_JOB_ID.SUCCESS:
      return {
        ...state,
        vmDetailByJobId: action.payload,
        vmDetailByJobIdErrorMessage: null,
      };
    case GET_ALLOY_VM_DETAILS_BY_JOB_ID.ERROR:
      return {
        ...state,
        vmDetailByJobId: [],
        vmDetailByJobIdErrorMessage: action.payload,
      };
    case CANCEL_ALLOY_VM_COMMAND_SCHEDULE.SUCCESS:
      return {
        ...state,
        cancelGrafanaAgentRemoteCommandScheduleSuccessMessage: action.payload,
        cancelGrafanaAgentRemoteCommandScheduleErrorMessage: null,
      };
    case CANCEL_ALLOY_VM_COMMAND_SCHEDULE.ERROR:
      return {
        ...state,
        cancelGrafanaAgentRemoteCommandScheduleSuccessMessage: null,
        cancelGrafanaAgentRemoteCommandScheduleErrorMessage: action.payload,
      };
    case SET_MODIFY_JOB_DETAILS:
      return {
        ...state,
        setModifyJobDetails: action.payload,
      };
    case RESET_CLONE_JOB_DETAILS:
      return {
        ...state,
        setCloneJobDetails: null,
      };
      case SET_SORTING: // New case for handling sorting
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortDirection: action.payload.sortDirection,
            };
        case SET_FILTERS: // New case for handling filters
            return {
                ...state,
                filters: action.payload,
            };
    default:
      return { ...state };
  }
};

export default gaAzureVmAlloyReducer;
