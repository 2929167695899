import React, {useState, useEffect} from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Switch, Redirect, useLocation, useHistory } from 'react-router-dom';

import MerakiFirewallAutomation from './MerakiFirewallAutomation';
import MerakiFirewallFileCompare from './meraki -firewall-file-compare/MerakiFirewallFileCompare';

const NetworkAutomation = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (pathname === "/admin/automation-logs/network-automation/meraki-firewall-logs") {
            setActiveIndex(0);
        } else if (pathname === "/admin/automation-logs/network-automation/meraki-firewall-file-compare") {
            setActiveIndex(1);
        }
    },[pathname])

    const handleTabChange = (e) => {
        if (e.index === 0) {
            setActiveIndex(0);
            history.push("/admin/automation-logs/network-automation/meraki-firewall-logs");
        } else if (e.index === 1) {
            setActiveIndex(1);
            history.push("/admin/automation-logs/network-automation/meraki-firewall-file-compare");
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <p className="page-label" style={{ padding: "24px 24px 0 24px" }}>
                Automation Logs
                <i className="pi pi-angle-right" />
                Network Automation
            </p>
            <TabView className="container-fluid" onTabChange={handleTabChange} activeIndex={activeIndex}>
                <TabPanel header="Meraki Firewall">
                    <MerakiFirewallAutomation/>
                </TabPanel>
                <TabPanel header="File Compare">
                    <MerakiFirewallFileCompare/>
                </TabPanel>
            </TabView>
            <Switch>
                <Redirect
                exact
                path="/admin/automation-logs/network-automation"
                to="/admin/automation-logs/network-automation/meraki-firewall-logs"/>
            </Switch>
        </div>
    )
}

export default NetworkAutomation;