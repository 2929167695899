import { call, put } from "redux-saga/effects";

import { 
    getMerakiFirewallAutomationLogsSuccess, 
    getMerakiFirewallAutomationLogsFailure,
    postModifyMerakiFirewallScheduleSuccess,
    postModifyMerakiFirewallScheduleError,
    cancelMerakiFirewallScheduleSuccess,
    cancelMerakiFirewallScheduleFailure,
    getMerakiFirewallAutomationRitsSuccess,
    getMerakiFirewallAutomationRitsFailure,
    postMerakiFirewallFileCompareSuccess,
    postMerakiFirewallFileCompareError,
    getMerakiFirewallFileCompareLogsSuccess,
    getMerakiFirewallFileCompareLogsError

} from "../components/network-automation/redux/actions";


import MerakiFirewallAutomationService from "../components/network-automation/MerakiFirewallAutomationService";

const merakiFirewallAutomationService = new MerakiFirewallAutomationService();

function* loadMerakiFirewallAutomationLogs({startDate, endDate, offset}) {
    try {
        const automationLogs = yield call(merakiFirewallAutomationService.getMerakiFirewallAutomationLogs, startDate, endDate, offset);
        yield put(getMerakiFirewallAutomationLogsSuccess(automationLogs));
    } catch(error) {
        yield put(getMerakiFirewallAutomationLogsFailure(error.toString()));
    }
}
function* saveModifiedSchedule({ modifiedSchedule }) {
    try {
        yield call(merakiFirewallAutomationService.postModifySchedule, modifiedSchedule);
        yield put(postModifyMerakiFirewallScheduleSuccess());
       setTimeout (()=>{
        window.location.reload();
       }, 2000); 
    } catch (error) {
        yield put(postModifyMerakiFirewallScheduleError(error.toString()));
    }
}

function* cancelMerakiFirewallSchedule({ cancelSchedule }) {
    try {
        yield call(merakiFirewallAutomationService.cancelSchedule, cancelSchedule);
        yield put(cancelMerakiFirewallScheduleSuccess());
        setTimeout (()=>{
            window.location.reload();
           }, 2500); 
    } catch (error) {
        yield put(cancelMerakiFirewallScheduleFailure(error.toString()));
    }
}

function* loadMerakiFirewallAutomationRits() {
    try {
        const rits = yield call(merakiFirewallAutomationService.getMerakiFirewallAutomationRITs);
        yield put(getMerakiFirewallAutomationRitsSuccess(rits));
    } catch(error) {
        yield put(getMerakiFirewallAutomationRitsFailure(error.toString()));
    }
}

function* saveMerakiFirewallFileCompare({ formData }) {
    try {
        const response = yield call(merakiFirewallAutomationService.postMerakiFirewallFileCompare, formData);
        yield put(postMerakiFirewallFileCompareSuccess(response));
        try {
            const fileCompareLogs = yield call(merakiFirewallAutomationService.getMerakiFirewallFileCompareLogs);
            yield put(getMerakiFirewallFileCompareLogsSuccess(fileCompareLogs));
        } catch (error) {
            yield put(getMerakiFirewallFileCompareLogsError(error.toString()));
        }
    } catch (error) {
        yield put(postMerakiFirewallFileCompareError(error.toString()));
    }
}

function* loadMerakiFirewallFileCompareLogs() {
    try {
        const fileCompareLogs = yield call(merakiFirewallAutomationService.getMerakiFirewallFileCompareLogs);
        yield put(getMerakiFirewallFileCompareLogsSuccess(fileCompareLogs));
    } catch (error) {
        yield put(getMerakiFirewallFileCompareLogsError(error.toString()));
    }
}

export {loadMerakiFirewallAutomationLogs, saveModifiedSchedule, cancelMerakiFirewallSchedule, loadMerakiFirewallAutomationRits, saveMerakiFirewallFileCompare, loadMerakiFirewallFileCompareLogs};