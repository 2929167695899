import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export default class GAAzureVmAlloyService {

	// Fetch VM list
	async getVmList() {
		const response = await axios.get(
			`${ApiPath.baseUrl}/azurevm/inventorydetails`,
			{ timeout: 120000, timeoutErrorMessage: "request timeout" }
		);
		if (response.status >= 400) {
		  throw new Error(response.errors || "Error fetching VM list.");
		}
		return response.data;
	  }

	async postAlloyVmCreateJob(job) {
		const response = await axios.post(
			`${ApiPath.baseUrl}/azurevm/job`,
			job,
			{ timeout: 120000, timeoutErrorMessage: "request timeout" }
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}

	async getAlloyVmJobDetails(
		startDate, 
		endDate, 
		offset,
		pageNumber = 1,
        pageSize = 10,
        sortBy = "job_id",
        sortDirection = "desc",
        filterCondition = null) {
		const requestConfiguration = {
			params: { 
				startDate: startDate , 
				endDate: endDate, 
				offset: offset,
				pageNumber :pageNumber,
                pageSize :pageSize,
                sortBy :sortBy,
                sortDirection :sortDirection,
                filterCondition: filterCondition ? JSON.stringify(filterCondition) : "null",
			 },
			timeout: 120000,
			timeoutErrorMessage: "request timeout",
		};
		const response = await axios.get(
			`${ApiPath.baseUrl}/azurevm/jobdetail`,
			requestConfiguration
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}

	async getServerDetailsById(id) {
		const requestConfig = {
			params: { jobId: id },
			timeout: 120000,
			timeoutErrorMessage: "request timeout",
		};
		const response = await axios.get(
			`${ApiPath.baseUrl}/azurevm/job`,
			requestConfig
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}

	async cancelJobSchedule(cancelSchedule) {
		console.log("cancelSchedule", cancelSchedule);
		const response = await axios.delete(
			`${ApiPath.baseUrl}/azurevm/job/${cancelSchedule}`,
			{ timeout: 120000, timeoutErrorMessage: "request timeout" }
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}
}
