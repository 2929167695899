import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator, PrimeIcons } from "primereact/api";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";

import Moment from "moment";

import {
	parseDateTimeWithoutSecsMillis,
	addDateTime,
} from "../../../common/date-time";
import {
	getJobDetailsInit,
	cancelGrafanaAgentRemoteCommandScheduleInit,
	setModifyJobDetails,
	setCloneJobDetails,
	resetModifyJobDetails,
	resetCloneJobDetails,
} from "../redux/actions";
import SubJobDetails from "./SubJobDetails";
import "./GARemoteCommandJobDetails.scss";
import GARemoteCommandJobDetailDialog from "./GARemoteCommandJobDetailDialog";

const GARemoteCommandJobDetails = () => {
	const [dialogData, setDialogData] = useState(null);

	const jobDetails = useSelector((state) => state.gaRemoteCommand.jobDetails);
	const userRoles = useSelector((state) => state.profile.userRoles);
	const history = useHistory();
	const dispatch = useDispatch();

	const [dateRange, setDateRange] = useState([
		addDateTime("day", -14),
		addDateTime("day", 0),
	]);
	const [expandedRows, setExpandedRows] = useState(null);
	const [tableFilters, setTableFilters] = useState(null);

	const jobDetailsTableRef = useRef(null);

	const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
	const endDate = !!dateRange[1]
		? Moment(dateRange[1]).format("yyyy-MM-DD")
		: Moment(dateRange[0]).format("yyyy-MM-DD");
	const offset = Moment(new Date()).utcOffset();

	useEffect(() => {
		initializeTableFilter();
		dispatch(resetModifyJobDetails());
		dispatch(resetCloneJobDetails());
		fetchJobDetails();
	}, []);

	const fetchJobDetails = () => {
		const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
		const endDate = !!dateRange[1]
			? Moment(dateRange[1]).format("yyyy-MM-DD")
			: Moment(dateRange[0]).format("yyyy-MM-DD");
		const offset = Moment(new Date()).utcOffset();
		dispatch(getJobDetailsInit(startDate, endDate, offset));
	};

	const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			jobId: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			division: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			serviceTypes: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			action: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			jobType: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			status: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
		});
	};

	const actionsTemplate = (rowData) => {
		if (!rowData) {
			return null;
		}

		const currentTime = new Date();
		const scheduledTime = new Date(rowData.scheduledDateTime);
		const isScheduledInPast = Moment(scheduledTime).isBefore(
			currentTime,
			"day"
		);

		const minsUntilScheduledTime = Moment(scheduledTime).diff(
			currentTime,
			"minutes"
		);
		//disable the action buttons if the status is not scheduled or if the scheduled time is in the past or if less than 15 minutes remaining until the scheduled time
		const isDisabled =
			rowData.status !== "Scheduled" ||
			isScheduledInPast ||
			minsUntilScheduledTime < 15;
		return (
			<div className="ga-remote-command-action-icons">
				<Button
					icon={PrimeIcons.PENCIL}
					className="p-button-text"
					disabled={isDisabled}
					onClick={() => modifyJobHandler(rowData)}
				/>
				<Button
					icon={PrimeIcons.TIMES_CIRCLE}
					className="p-button-text p-button-danger"
					disabled={isDisabled}
					onClick={(event) =>
						confirmPopup({
							target: event.currentTarget,
							icon: "pi pi-info-circle",
							message: "Do you want to cancel this schedule?",
							accept: () => handleCancelClick(rowData),
						})
					}
				/>

				<Button
					icon={PrimeIcons.CLONE}
					className="p-button-text"
					onClick={() => cloneJobHandler(rowData)}
				/>

				<Button
					icon="pi pi-eye"
					className="p-button-text"
					onClick={() => setDialogData(rowData)}
				/>
			</div>
		);
	};

	/**
	 * Function to export table data
	 */
	const exportJobDetails = () => jobDetailsTableRef.current.exportCSV();

	const rowExpansionTemplate = ({ jobId, serviceType }) => (
		<SubJobDetails jobId={jobId} serviceType={serviceType} />
	);

	const onRowExpand = (event) => {
		let _expandedRows = {};
		_expandedRows[`${event.data.jobId}`] = true;
		setExpandedRows(_expandedRows);
	};

	const onRowCollapse = (event) => {
		setExpandedRows(null);
	};

	const jobTypeTemplate = ({ jobType }) =>
		jobType === "Execute" ? jobType + " Now" : jobType;

	const scheduledAtTemplate = ({ scheduledDateTime }) =>
		scheduledDateTime ? (
			parseDateTimeWithoutSecsMillis(scheduledDateTime)
		) : (
			<span style={{ marginLeft: "43%" }}>N.A</span>
		);
	const serverTypeTemplate = ({ serverTypes }) => {
		return (
			<>
				{serverTypes && serverTypes.length > 0 ? (
					serverTypes.join(", ")
				) : (
					<span style={{ marginLeft: "50%" }}>-</span>
				)}
			</>
		);
	};
	const serviceTypeTemplate = ({ serviceTypes }) => {
		return (
			<>
				{serviceTypes && serviceTypes.length > 0 ? (
					serviceTypes.map((service) => <div key={service}>{service}</div>)
				) : (
					<span style={{ marginLeft: "50%" }}>-</span>
				)}
			</>
		);
	};

	const divisionTemplate = ({ division }) => {
		return (
			<>
				{division ? (
					division.split(",").map((divson) => <div key={divson}>{divson}</div>)
				) : (
					<span style={{ marginLeft: "50%" }}>-</span>
				)}
			</>
		);
	};

	const modifyJobHandler = (rowData) => {
		console.log("rowData inside modifyhandler", rowData);
		const modifyJobDetails = {
			division: rowData.division,
			serverTypes: rowData.serverTypes,
			serviceTypes: rowData.serviceTypes,
			selectedStore: rowData.selectedStore,
			command: rowData.action,
			scheduledDateTime: rowData.scheduledDateTime,
			forceUpdate: rowData.forceUpdate,
			jobId: rowData.jobId,
			jobType: rowData.jobType,
			ritNumber: rowData.itsmId,
		};

		dispatch(setModifyJobDetails(modifyJobDetails));
		history.push("/admin/grafana-agent/server/create-job");
	};

	const cloneJobHandler = (rowData) => {
		console.log("rowData inside cloneJobHandler", rowData);
		const cloneJobDetails = {
			division: rowData.division,
			serverTypes: rowData.serverTypes,
			serviceTypes: rowData.serviceTypes,
			selectedStore: rowData.selectedStore,
			command: rowData.action,
		};
		dispatch(setCloneJobDetails(cloneJobDetails));
		history.push("/admin/grafana-agent/server/create-job");
	};
	const handleCancelClick = (rowData) => {
		const cancelSchedule = rowData.jobId;

		dispatch(
			cancelGrafanaAgentRemoteCommandScheduleInit(
				cancelSchedule,
				startDate,
				endDate,
				offset
			)
		);
	};
	const storeServiceCountTemplate = ({ storeCount, totalServiceCount }) => (
		<>
			Store Count-{storeCount}
			{!!totalServiceCount && (
				<>
					{" "}
					<br />
					Service Count-{totalServiceCount}
				</>
			)}
		</>
	);

	const progressTemplate = ({
		completedCount,
		inProgressCount,
		failedCount,
	}) => (
		<>
			Completed-{completedCount}
			{!!inProgressCount && (
				<>
					{" "}
					<br />
					In Progress-{inProgressCount}
				</>
			)}
			{!!failedCount && (
				<>
					{" "}
					<br />
					Failed-{failedCount}
				</>
			)}
		</>
	);
	return (
		<div className="ga-remote-command-job-details-container ">
			<GARemoteCommandJobDetailDialog
				dialogData={dialogData}
				setDialogData={setDialogData}
			/>
			<div className="row summary-action">
				<div className="col-12 col-lg-6 input-field">
					<label htmlFor="dateRange">Events Date</label>
					<Calendar
						id="dateRange"
						value={dateRange}
						onChange={(e) => setDateRange(e.value)}
						selectionMode="range"
						minDate={addDateTime("month", -3)}
						maxDate={addDateTime("month", 0)}
						showIcon
					/>
				</div>
			</div>
			<div className="row summary-action">
				<div className="col-12 ">
					<Button label="Get Details" onClick={fetchJobDetails} />
					<Button
						icon="pi pi-download"
						className="p-button-text"
						onClick={exportJobDetails}
						style={{ float: "right" }}
					/>
					<Button
						icon="pi pi-refresh"
						className="p-button-text"
						onClick={fetchJobDetails}
						style={{ float: "right" }}
					/>
				</div>
			</div>
			<div>
				<ConfirmPopup />
				<DataTable
					value={jobDetails}
					ref={jobDetailsTableRef}
					// className="p-datatable-gridlines"
					sortField="createdDateTime"
					sortOrder={-1}
					filters={tableFilters}
					filterDisplay="menu"
					paginator
					paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
					currentPageReportTemplate="Items per page"
					rows={10}
					rowsPerPageOptions={[10, 20, 50]}
					emptyMessage="No logs found"
					expandedRows={expandedRows}
					// onRowToggle={(e) => setExpandedRows(e.data)}
					onRowExpand={onRowExpand}
					onRowCollapse={onRowCollapse}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="jobId"
				>
					<Column expander style={{ width: "5em" }} />
					<Column field="jobId" header="Job ID" sortable filter />
					<Column
						field="division"
						header="Division"
						body={divisionTemplate}
						filter
					/>
					<Column
						field="serverTypes"
						header="Server Types"
						body={serverTypeTemplate}
					/>
					<Column
						field="serviceTypes"
						header="Service"
						body={serviceTypeTemplate}
						sortable
						filter
					/>
					<Column field="action" header="Job Action" filter />

					<Column
						field="jobType"
						header="Job Type"
						body={jobTypeTemplate}
						sortable
						filter
					/>

					<Column
						field="scheduledDateTime"
						header="Scheduled At"
						body={scheduledAtTemplate}
						sortable
					/>
					<Column
						field="storeCount"
						header="Stores-Services Count"
						body={storeServiceCountTemplate}
						sortable
					/>
					<Column
						field="completedCount"
						body={progressTemplate}
						header="Progress"
						sortable
					/>

					<Column field="status" header="Status" sortable filter />
					{userRoles.includes("GARemoteCommand.Write") && (
						<Column header="Actions" body={actionsTemplate} />
					)}
				</DataTable>
			</div>
		</div>
	);
};

export default GARemoteCommandJobDetails;
