export const GET_MERAKI_FIREWALL_AUTOMATION_LOGS = {
    INIT: "GET_MERAKI_FIREWALL_AUTOMATION_LOGS_INIT",
    SUCCESS: "GET_MERAKI_FIREWALL_AUTOMATION_LOGS_SUCCESS",
    ERROR: "GET_MERAKI_FIREWALL_AUTOMATION_LOGS_ERROR"
}

export const POST_MODIFY_MERAKI_FIREWALL_SCHEDULE ={
    INIT: "POST_MODIFY_MERAKI_FIREWALL_SCHEDULE_INIT",
    SUCCESS: "POST_MODIFY_MERAKI_FIREWALL_SCHEDULE_SUCCESS",
    ERROR: "POST_MODIFY_MERAKI_FIREWALL_SCHEDULE_ERROR"
}

export const CANCEL_MERAKI_FIREWALL_SCHEDULE= {
    INIT: "CANCEL_MERAKI_FIREWALL_SCHEDULE_INIT",
    SUCCESS: "CANCEL_MERAKI_FIREWALL_SCHEDULE_SUCCESS",
    ERROR: "CANCEL_MERAKI_FIREWALL_SCHEDULE_ERROR"
}

export const GET_MERAKI_FIREWALL_AUTOMATION_RITS = {
    INIT: "GET_MERAKI_FIREWALL_AUTOMATION_RITS_INIT",
    SUCCESS: "GET_MERAKI_FIREWALL_AUTOMATION_RITS_SUCCESS",
    ERROR: "GET_MERAKI_FIREWALL_AUTOMATION_RITS_ERROR"
}

export const POST_MERAKI_FIREWALL_FILE_COMPARE = {
    INIT: "POST_MERAKI_FIREWALL_FILE_COMPARE_INIT",
    SUCCESS: "POST_MERAKI_FIREWALL_FILE_COMPARE_SUCCESS",
    ERROR: "POST_MERAKI_FIREWALL_FILE_COMPARE_ERROR"
}

export const GET_MERAKI_FIREWALL_FILE_COMPARE_LOGS = {
    INIT: "GET_MERAKI_FIREWALL_FILE_COMPARE_LOGS_INIT",
    SUCCESS: "GET_MERAKI_FIREWALL_FILE_COMPARE_LOGS_SUCCESS",
    ERROR: "GET_MERAKI_FIREWALL_FILE_COMPARE_LOGS_ERROR"
}