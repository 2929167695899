import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useSelector, useDispatch } from 'react-redux';
import Moment from "moment";

import { parseDateTime, addDateTime} from '../../common/date-time';
import { getAllSnowAppsInit, getAllSnowWrapperLogsInit } from './redux/actions';
import SnowWrapperDetailDialog from './SnowWrapperDetailDialog';
import './SnowWrapper.scss';


const SnowWrapper = () => {

    const snowAppList = useSelector(state => state.snowWrapper.snowAppList);
    const snowWrapperLogs = useSelector(state => state.snowWrapper.snowWrapperLogs);
    const totalRecords = useSelector(state => state.snowWrapper.totalRecords); // Redux total records
    const dispatch = useDispatch();
    const [selectedSnowApp, setSelectedSnowApp] = useState([]);
    const [dateRange, setDateRange] = useState([
        addDateTime("day", -2),
        addDateTime("day", 0),
    ]);
    const [tableFilters, setTableFilters] = useState(null);
    const [filterCondition, setFilterCondition] = useState(""); // **Added filterCondition state**
    const [dialogData, setDialogData] = useState(null);

    const toastRef = useRef(null);
    const logsTableRef = useRef(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0, // Starting index
        rows: 10, // Default page size
        page: 0, // Current page number
        sortField: "kafkaQueuedTimestamp", // Default sort field
        sortOrder: -1, // Default sort order (-1 for descending, 1 for ascending)
    });

    useEffect(() => {
        dispatch(getAllSnowAppsInit());
        initializeTableFilter();
    }, []);

    /**
     * Select all snow apps by default
     */
    useEffect(() => {
        setSelectedSnowApp([...snowAppList]);
        if(snowAppList.length>0) {
            fetchSnowWrapperLogs(true);
        }
    }, [snowAppList]);

    /**
     * Fetch Snow logs (server-side pagination, sorting, filtering)
     */
    const fetchSnowWrapperLogs = (initialLoad,params=lazyParams,currentFilters=filterCondition) => {
       const sortDirection= params.sortOrder== -1?'DESC' : 'ASC';
        let snowApps = selectedSnowApp.join(",");
        if (selectedSnowApp.length === 0 && initialLoad !== true) {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: 'No App Selected', life: 2000 });
            return;
        } else if (initialLoad === true) {
            snowApps = snowAppList.join(",");
        }

        const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
        const endDate = !!dateRange[1] ? Moment(dateRange[1]).format("yyyy-MM-DD") : Moment(dateRange[0]).format("yyyy-MM-DD");
        const offset = Moment(new Date()).utcOffset();

        const filterConditionPayload = constructFilterParams(currentFilters);
        // **Updated action call to include filterCondition**
        dispatch(getAllSnowWrapperLogsInit(
            snowApps,
            startDate,
            endDate,
            offset,
            params.page, // Current page number
            params.rows, // Number of rows per page
            params.sortField, // Sorting field
            sortDirection, // Sorting order
            filterConditionPayload // **Filter condition**
        ));
    }


    const constructFilterParams = (filterConditions) => {
        if (!filterConditions || typeof filterConditions !== "object") {
          return ""; // Return empty string if no filters are present
        }
      
        const queryParams = [];
      
        Object.keys(filterConditions).forEach((key) => {
          const filter = filterConditions[key];
          const constraints = filter?.constraints;
      
          if (constraints && constraints.length > 0) {
            const { value, matchMode } = constraints[0];
      
            if (value !== undefined && value !== null && value !== "") {
              queryParams.push(
                `${encodeURIComponent(key)}&${encodeURIComponent(matchMode)}&${encodeURIComponent(value)}`
              );
            }
          }
        });
      
        return queryParams.join(",");
      };
      


    /**
     * Initialize table filter
     */
    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			appName: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            requestId: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            queueStatus : {
                operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
		})
	}

    /**
     * Handle filtering event
     */
    const onFilter = (filters) => {
        console.log("filters",filters);
        setFilterCondition(filters);
        const updatedParams={
            ...lazyParams, 
              first: 0,
              page: 0
        };
        setLazyParams(updatedParams); // Reset to first page
        fetchSnowWrapperLogs(false,updatedParams,filters); // Fetch filtered data
    };

    /**
     * Handle pagination event
     */
    const onPage = (event) => {
        console.log( "pageevnt",event);
        const updatedParams=
        {
            ...lazyParams,
            first: event.first, // Start index
            rows: event.rows, // Rows per page
            page: event.page // Current page number
        };
        setLazyParams(updatedParams);
        console.log( "page",lazyParams.page, // Current page number
            lazyParams.rows, // Number of rows per page
            lazyParams.sortField)
        fetchSnowWrapperLogs(false,updatedParams); // Fetch data for the new page
    };

    /**
     * Handle sorting event
     */
    const onSort = (event) => {
       const updatedParams={
        ...lazyParams,
        sortField: event.sortField, // Sorting field
        sortOrder: event.sortOrder // Sorting order
    };
        setLazyParams(updatedParams);
        fetchSnowWrapperLogs(false,updatedParams); // Fetch sorted data
    };


    /**
     * Columns template 
     */
    const kafkaQueuedTimestampTemplate = ({kafkaQueuedTimestamp}) => kafkaQueuedTimestamp ? parseDateTime(kafkaQueuedTimestamp) : <span style={{marginLeft: '50%'}}>-</span>; 
    const requestReceivedAtTemplate = ({requestReceived}) => requestReceived ? parseDateTime(requestReceived) : <span style={{marginLeft: '50%'}}>-</span>;
    const requestQueuedAtTemplate = ({requestQueued}) => requestQueued ? parseDateTime(requestQueued) : <span style={{marginLeft: '50%'}}>-</span>;
    const requestProcessedAtTemplate = ({requestProcessed}) => requestProcessed ? parseDateTime(requestProcessed) : <span style={{marginLeft: '50%'}}>-</span>;
    const requestResponseTemplate = ({request, response}) => <i className="pi pi-eye" onClick={() => setDialogData({request, response})}></i>

    /**
   * Function to export table data
   */
const exportSnowWrapperLogs = () => logsTableRef.current.exportCSV();

    return (
        <div className="snow-wrapper-container container-fluid">
            <Toast ref={toastRef} position="bottom-center" />
            <p className="page-label">
                SNOW Wrapper
            </p>
            <SnowWrapperDetailDialog dialogData={dialogData} setDialogData={setDialogData} />
            <div className="snow-wrapper-action row">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="snow-apps">App Name</label>
                    <MultiSelect
                        id="snow-apps"
                        value={selectedSnowApp}
                        options={snowAppList}
                        onChange={e => setSelectedSnowApp(e.value)} />
                </div>
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="dateRange">Events Date</label>
                    <Calendar
                        id="dateRange"
                        value={dateRange}
                        onChange={(e) => setDateRange(e.value)}
                        selectionMode="range"
                        minDate={addDateTime("month", -3)}
                        maxDate={addDateTime("month", 0)}
                        showIcon />
                </div>
                <div className="col-12">
                    <Button label="Get Logs" onClick={fetchSnowWrapperLogs} />
                    <Tooltip target=".message-table-info-icon" />
        <i className="pi pi-info-circle message-table-info-icon" data-pr-tooltip="Max records shown/downloadable: 10,000" data-pr-position="right" style={{marginLeft:"7px"}} />
                    <Button 
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportSnowWrapperLogs}
                        style={{float: "right"}}
                    />
                    <Button 
                        id='refreshBtn'
                        icon="pi pi-refresh"
                        className="p-button-text"
                        onClick={fetchSnowWrapperLogs}                       
                        style={{float: "right"}}
                    />
                </div>
            </div>
            <DataTable value={snowWrapperLogs} ref={logsTableRef}  filters={tableFilters} 
            filterDisplay="menu" 
            onFilter={(e) => onFilter(e.filters)} // **Handle filtering event**
            sortField={lazyParams.sortField} // Dynamic sort field
            sortOrder={lazyParams.sortOrder} // Dynamic sort order
            lazy
            paginator
            totalRecords={totalRecords} // Dynamic total records from Redux
            first={lazyParams.first} // Start index
            rows={lazyParams.rows} // Rows per page
            rowsPerPageOptions={[10, 20, 50]} // Page size options
            onPage={onPage} // Pagination handler
            onSort={onSort} // Sorting handler
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            emptyMessage="No logs found.">
                <Column header="App Name" field="appName" sortable filter />
                <Column header="Request ID" field="requestId" sortable filter />
                <Column header="Status" field="queueStatus" sortable filter />
                <Column header="Request Queued At" field="kafkaQueuedTimestamp" body={kafkaQueuedTimestampTemplate} sortable />
                <Column header="Request Received At" field="requestReceived" body={requestReceivedAtTemplate} sortable />
                <Column header="Request Processed At" field="requestProcessed" body={requestProcessedAtTemplate} sortable />
                <Column header="Request Retried At" field="requestQueued" body={requestQueuedAtTemplate} sortable />
                <Column header="Request / Response" body={requestResponseTemplate} />
            </DataTable>
        </div>
    )
}

export default SnowWrapper;