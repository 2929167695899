import React, { useState, useEffect } from "react";
import { useLocation, Switch, Redirect, useHistory } from "react-router-dom";
import GAAzureVmAlloyJobDetails from "./job-details/GAAzureVmAlloyJobDetails";
import GAAzureVmAlloyCreateJob from "./create-job/GAAzureVmAlloyCreateJob";

import { TabView, TabPanel } from "primereact/tabview";
import { useSelector } from "react-redux";

const GAAzureVmAlloy = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const { pathname } = useLocation();
	const history = useHistory();

	const userRoles = useSelector((state) => state.profile.userRoles);

	useEffect(() => {
		if (pathname === "/admin/grafana-agent/azure-vm-alloy/job-details") {
			setActiveIndex(0);
		} else if (
			pathname === "/admin/grafana-agent/azure-vm-alloy/create-job"
		) {
			setActiveIndex(1);
		}
	}, [pathname]);

	const handleTabChange = (e) => {
		if (e.index === 0) {
			setActiveIndex(0);
			history.push("/admin/grafana-agent/azure-vm-alloy/job-details");
		} else if (e.index === 1) {
			setActiveIndex(1);
			history.push("/admin/grafana-agent/azure-vm-alloy/create-job");
		}
	};

	return (
		<div style={{ width: "100%" }}>
			<p className="page-label" style={{ padding: "22px 18px 0 18px" }}>
				Grafana Azure Standard VM Alloy
			</p>
			<TabView
				activeIndex={activeIndex}
				onTabChange={handleTabChange}
				className="container-fluid"
			>
				<TabPanel header="View Job">
					<GAAzureVmAlloyJobDetails />
				</TabPanel>
				{userRoles.includes("GARemoteCommand.Write") && (
					<TabPanel header="Create Job">
						<GAAzureVmAlloyCreateJob />
					</TabPanel>
				)}
			</TabView>
			<Switch>
				<Redirect
					exact
					path="/admin/grafana-agent"
					to="/admin/grafana-agent/azure-vm-alloy/job-details"
				/>
			</Switch>
		</div>
	);
};

export default GAAzureVmAlloy;
