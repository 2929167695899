import React, {useState, useEffect, useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterOperator, FilterMatchMode } from 'primereact/api';
import { useSelector, useDispatch } from "react-redux";

import { parseDateTime } from "../../../common/date-time";
import { getMerakiFirewallFileCompareLogsInit } from "../redux/actions";
import MerakiFirewallAutomationService from "../MerakiFirewallAutomationService";

const merakiAutomationService = new MerakiFirewallAutomationService();

const MerakiFirewallFileCompareLogs = () => {
    const fileCompareLogs = useSelector(state => state.merakiFirewall.merakiFirewallFileCompareLogs);
    const dispatch = useDispatch();
    const [tableFilters, setTableFilters] = useState(null);
    const fileCompareLogsRef = useRef(null);

    useEffect(() => {
      fetchMerakiFirewallFileCompareLogs();
      initializeTableFilter();
    }, []);

    const fetchMerakiFirewallFileCompareLogs = () => {
      dispatch(getMerakiFirewallFileCompareLogsInit());
    };

    const exportCompareLogs = () => {
      fileCompareLogsRef.current.exportCSV();
    }

    const initializeTableFilter = () => {
        setTableFilters({
            global: {value: null, matchMode: FilterMatchMode.CONTAINS},
              createdUser: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              sourceFile: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              targetFile: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              status: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              }
        });
    }

    const createdAtTemplate = ({createdDateTime}) => parseDateTime(createdDateTime);
    const processedDateTemplate = ({processedDateTime}) => processedDateTime ? parseDateTime(processedDateTime) : "-";

    const outputFileTemplate = ({outputFile}) => (
      !!outputFile ?
      (<i className="pi pi-download" id="downloadbtn" onClick={() =>
        merakiAutomationService.getMerakiFirewallFileCompareOutputFile(outputFile).
        then((url) => window.open(url, "_blank") )} style={{cursor: "pointer"}}/>) : "-"
    )

    return (
        <div>
          <div>
            <strong>File Compare Logs</strong>
            <Button className="p-button-text" icon="pi pi-download" id="exportbtn" onClick={exportCompareLogs} style={{float: "right"}}/>
            <Button className="p-button-text" icon="pi pi-refresh" onClick={fetchMerakiFirewallFileCompareLogs} style={{float: "right"}}/>
          </div>
            <DataTable
            value={fileCompareLogs}
            ref={fileCompareLogsRef}
            filters={tableFilters}
            filterDispay="menu"
            sortField="createdDateTime"
            sortOrder={-1}
            paginator
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Items per page"
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
            emptyMessage="No records found"
            >
                <Column header="Created By" field="createdUser" filter/>
                <Column header="Created At" field="createdDateTime" body={createdAtTemplate} sortable/>
                <Column header="Source File" field="sourceFile" filter/>
                <Column header="Target File" field="targetFile" filter/>
                <Column header="Status" field="status" filter/>
                <Column header="Processed At" field="processedDateTime" body={processedDateTemplate} sortable/>
                <Column header="Output File" body={outputFileTemplate}/>

            </DataTable>
        </div>
    );
};
export default MerakiFirewallFileCompareLogs;