import { call, put } from "redux-saga/effects";

import {
  getVmListSuccess,
  getVmListFailure,
  postAlloyVmCreateJobSuccess,
  postAlloyVmCreateJobFailure,
  getAlloyVmJobDetailsSuccess,
  getAlloyVmJobDetailsFailure,
  getAlloyVmDetailsByJobIdSuccess,
  getAlloyVmDetailsByJobIdFailure,
  cancelAlloyVmRemoteCommandScheduleSuccess,
  cancelAlloyVmRemoteCommandScheduleFailure,
} from "../components/ares/redux/actions";
import GAAzureVmAlloyService from "../components/ares/GAAzureVmAlloyService";

const gaAzureVmAlloyService = new GAAzureVmAlloyService();


// Fetch VM list
function* fetchVmList() {
  try {
    const response = yield call(gaAzureVmAlloyService.getVmList);
    console.log("load vm details",response);
    yield put(getVmListSuccess(response));
  } catch (error) {
    yield put(getVmListFailure(error.message));
  }
}

function* postAzureCreatedJob(action) {
  try {
    const createJob = yield call(
      gaAzureVmAlloyService.postAlloyVmCreateJob,
      action.payload
    );
    console.log("Create Job Api response", createJob);
    yield put(postAlloyVmCreateJobSuccess(createJob));
  } catch (error) {
    yield put(postAlloyVmCreateJobFailure(error.toString()));
  }
}



function* loadAzureJobDetails({ startDate, endDate, offset, pageNumber, pageSize, sortBy, sortDirection, filterCondition }) {
  try {
    const jobDetails = yield call(
      gaAzureVmAlloyService.getAlloyVmJobDetails,
      startDate,
      endDate,
      offset,
      pageNumber,
      pageSize,
      sortBy,
      sortDirection,
      filterCondition || null
    );
    console.log("filterParams saga",filterCondition)
    yield put(getAlloyVmJobDetailsSuccess(jobDetails));
  } catch (error) {
    yield put(getAlloyVmJobDetailsFailure(error.toString()));
  }
}

function* loadAzureServerDetailsByJobId({ jobId }) {
  try {
    const serverDetailsByJobId = yield call(
      gaAzureVmAlloyService.getServerDetailsById,
      jobId
    );
    yield put(getAlloyVmDetailsByJobIdSuccess(serverDetailsByJobId));
  } catch (error) {
    yield put(getAlloyVmDetailsByJobIdFailure(error.toString()));
  }
}

function* cancelAzureGrafanaAgentScheduledJob({
  cancelSchedule,
  startDate,
  endDate,
  offset,
}) {
  console.log("cancelSchedule", cancelSchedule, startDate, endDate, offset);
  try {
    yield call(gaAzureVmAlloyService.cancelJobSchedule, cancelSchedule);
    yield put(cancelAlloyVmRemoteCommandScheduleSuccess());
    try {
      const jobDetails = yield call(
        gaAzureVmAlloyService.getAlloyVmJobDetails,
        startDate,
        endDate,
        offset
      );
      yield put(getAlloyVmJobDetailsSuccess(jobDetails));
    } catch (error) {
      yield put(getAlloyVmJobDetailsFailure(error.toString()));
    }
  } catch (error) {
    yield put(cancelAlloyVmRemoteCommandScheduleFailure(error.toString()));
  }
}

export {
  fetchVmList,
  postAzureCreatedJob,
  loadAzureJobDetails,
  loadAzureServerDetailsByJobId,
  cancelAzureGrafanaAgentScheduledJob,
};
