import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getMerakiFirewallAutomationRitsInit, postMerakiFirewallFileCompareInit } from '../redux/actions';
import MerakiFirewallFileCompareLogs from './MerakiFirewallFileCompareLogs';
import MerakiFirewallFileInput from './MerakiFirewallFileInput';
import './MerakiFirewallFileCompare.scss';

const MerakiFirewallFileCompare = () => {
    const fileTypes = [
        { label: 'RIT', value: 'rit' },
        { label: 'File', value: 'file' }
    ];

    const [file1Type, setFile1Type] = useState(fileTypes[0].value);
    const [file1, setFile1] = useState(null);
    const [file2Type, setFile2Type] = useState(fileTypes[0].value);
    const [file2, setFile2] = useState(null);
    const [file1Error, setFile1Error] = useState(false);
    const [file2Error, setFile2Error] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);

    const file1Ref = useRef(null);
    const file2Ref = useRef(null);

    const merakiFirewallAutomationRits = useSelector(state => state.merakiFirewall.merakiFirewallAutomationRits);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMerakiFirewallAutomationRitsInit());
    }, [dispatch]);

    const handleFile1RITSelect = (e, error = false) => {
        setFile1(e.value);
        setFile1Error(error);
        setErrorMessage(null);
    };

    const handleFile2RITSelect = (e, error = false) => {
        setFile2(e.value);
        setFile2Error(error);
        setErrorMessage(null);
    };

    const handleFile1Select = (file, error = false) => {
        setFile1(file);
        setFile1Error(error);
        setErrorMessage(null);
    }

    const handleFile2Select = (file, error = false) => {
        setFile2(file);
        setFile2Error(error);
        setErrorMessage(null);
    };

    const handleFile1TypeChange = (e) => {
        setFile1Type(e.value);
        setFile1(null);
        setErrorMessage(null);
    };

    const handleFile2TypeChange = (e) => {
        setFile2Type(e.value);
        setFile2(null);
        setErrorMessage(null);
    };

    const validateFiles = (file1, file2) => {
        let errors = [];
        if (!file1) errors.push('file1');
        if (!file2) errors.push('file2');
        if (file1 && file2 && (file1 === file2 || (file1.name && file2.name && file1.name === file2.name))) {
            errors.push('Source and Target files should be different');
        }
        return errors;
    };

    const createFormData = (file1, file2) => {
        const formData = new FormData();
        if (typeof file1 === 'string') {
            formData.append('sourceRit', file1);
        } else {
            formData.append('sourceFile', file1);
        }
        if (typeof file2 === 'string') {
            formData.append('targetRit', file2);
        } else {
            formData.append('targetFile', file2);
        }
        return formData;
    };

    const handleSubmit = () => {
        const errors = validateFiles(file1, file2);
        if (errors.length > 0) {
            if (errors.includes('file1')) setFile1Error(true);
            if (errors.includes('file2')) setFile2Error(true);
            if (errors.includes('Source and Target files should be different')) {
                setErrorMessage(["Source and Target files should be different"]);
            }
            return;
        }

        const formData = createFormData(file1, file2);
        dispatch(postMerakiFirewallFileCompareInit(formData));
        setFile1(null);
        setFile2(null);

        if (file1Type === "file") file1Ref.current.clear();
        if (file2Type === "file") file2Ref.current.clear();
    };

    const handleClear = () => {
        setFile1(null);
        setFile2(null);
        setFile1Error(false);
        setFile2Error(false);
        setErrorMessage(null);
        file1Type === "file" && file1Ref.current.clear();
        file2Type === "file" && file2Ref.current.clear();
    };

    return (
        <div className="meraki-firewall-file-compare-container container-fluid">
            <div className="row">
                <div className="col-5 border-right">
                    <div className='row mb-3'>
                    <strong>File Compare Request</strong>
                    </div>
                    <MerakiFirewallFileInput
                        fileTypeLabel="Source File Type"
                        fileLabel="Source File"
                        setDisplayDialog={setDisplayDialog}
                        fileRef={file1Ref}
                        fileType={file1Type}
                        fileTypes={fileTypes}
                        file={file1}
                        onFileSelect={handleFile1Select}
                        onRITSelect={handleFile1RITSelect}
                        onFileClear={() => setFile1(null)}
                        onFileTypeChange={handleFile1TypeChange}
                        options={merakiFirewallAutomationRits}
                        placeholder="Select RIT"
                        error={file1Error}
                    />
                    <MerakiFirewallFileInput
                        fileTypeLabel="Target File Type"
                        fileLabel="Target File"
                        setDisplayDialog={setDisplayDialog}
                        fileRef={file2Ref}
                        fileType={file2Type}
                        fileTypes={fileTypes}
                        file={file2}
                        onFileSelect={handleFile2Select}
                        onRITSelect={handleFile2RITSelect}
                        onFileClear={() => setFile2(null)}
                        onFileTypeChange={handleFile2TypeChange}
                        options={merakiFirewallAutomationRits}
                        placeholder="Select RIT"
                        error={file2Error}
                    />
                    {errorMessage && (
                        <div className="row col-12">
                            <p className="p-error">{errorMessage}</p>
                        </div>
                    )}
                    <div className="row input-field">
                        <Button label="Compare" onClick={handleSubmit}/>
                        <Button label="Clear" className="p-button-secondary" onClick={handleClear} />
                    </div>
                    <Dialog header="Alert" visible={displayDialog} style={{ width: '30vw' }} onHide={() => setDisplayDialog(false)}>
                        Please submit the request to upload and compare files
                    </Dialog>
                </div>
                <div className="col-7">
                    <MerakiFirewallFileCompareLogs />
                </div>
            </div>
        </div>
    );
};

export default MerakiFirewallFileCompare;