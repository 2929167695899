import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';

function CustomErrorToast() {

    /**
     * Get Toast Severity type based on Message
     * @param {string} msg 
     * @returns Toast Severity
     */
    const getSeverity = msg => {
        if(msg.includes("Exclusion Rule") || msg.includes("Successfully") || msg.includes("successfully modified") || msg.includes("Campaign created") || msg.includes("Group created") || msg.includes("Group Details modified") || msg.includes("Campaign Details modified") ) 
            return "success";
        if(msg.includes("NO_DATA_FOUND") || msg.includes("Command disabled") || msg.includes("No job details available"))
            return "warn";
        return "error";
    }
    // || msg.includes("scheduled job successfully") || msg.includes("Execution initiated")
    // || msg.includes("Job Excecution started")

    /**
     * Get Toast Summary type based on Message
     * @param {string} msg 
     * @returns Toast Summary
     */
    const getSummary = msg => {
        if(msg.includes("Exclusion Rule") || msg.includes("Successfully") || msg.includes("successfully modified") || msg.includes("Campaign created") || msg.includes("Group created") || msg.includes("Group Details modified") || msg.includes("Campaign Details modified"))
            return "Success";
        if(msg.includes("NO_DATA_FOUND") || msg.includes("Command disabled") || msg.includes("No job details available"))
            return "Info";
        return "Error";
    }

    const toastBC = useRef(null);
    const message = useSelector(state => state.toast.message) || 'Error Processing Request';
  
    useEffect(()=>{
        toastBC.current.show({severity:getSeverity(message), summary: getSummary(message), detail:message, life: message.includes("NO_DATA_FOUND")?1000:60000});
    },[])
 return (
    <Toast ref={toastBC} position="bottom-center" />
 )
}

export default CustomErrorToast