import { 
  GET_MERAKI_FIREWALL_AUTOMATION_LOGS,
  POST_MODIFY_MERAKI_FIREWALL_SCHEDULE,
  CANCEL_MERAKI_FIREWALL_SCHEDULE,
  GET_MERAKI_FIREWALL_AUTOMATION_RITS,
  POST_MERAKI_FIREWALL_FILE_COMPARE,
  GET_MERAKI_FIREWALL_FILE_COMPARE_LOGS

} from "./actionTypes";

const initialState = {
    merakiFirewallAutomationLogs: [],
    merakiFirewallAutomationLogsErrorMessage: null,
    modifiedSchedule: null,
    modifiedScheduleSuccessMessage: null,
    modifiedScheduleErrorMessage: null,
    cancelSchedule: null,
    cancelScheduleSuccessMessage: null,
    cancelScheduleErrorMessage: null,
    merakiFirewallAutomationRits: [],
    merakiFirewallAutomationRitsErrorMessage: null,
    merakiFirewallFileCompareSuccess: null,
    merakiFirewallFileCompareErrorMessage: null,
    merakiFirewallFileCompareLogs: [],
    merakiFirewallFileCompareLogsErrorMessage: null,

}

const merakiFirewallAutomationReducer = (state = initialState, action = {type: ""}) => {
    switch (action.type) {
    case GET_MERAKI_FIREWALL_AUTOMATION_LOGS.SUCCESS:
      return {
        ...state,
        merakiFirewallAutomationLogs: action.payload,
        merakiFirewallAutomationLogsErrorMessage: null,
      };
    case GET_MERAKI_FIREWALL_AUTOMATION_LOGS.ERROR:
      return {
        ...state,
        merakiFirewallAutomationLogs: [],
        merakiFirewallAutomationLogsErrorMessage: action.payload,
      };
      case POST_MODIFY_MERAKI_FIREWALL_SCHEDULE:
            return {
                ...state,
                modifiedSchedule: action.payload,
            };
      case POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.SUCCESS:
            return{
              ...state,
              modifiedScheduleSuccessMessage: action.payload,

            };
      case POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.ERROR:
            return{
              ...state,
              modifiedScheduleErrorMessage: action.payload,
            }
      case  CANCEL_MERAKI_FIREWALL_SCHEDULE:
        return {
            ...state,
            cancelSchedule: action.payload,
        };
        case  CANCEL_MERAKI_FIREWALL_SCHEDULE.SUCCESS:
        return {
            ...state,
            cancelScheduleSuccessMessage: action.payload,
        };
        case  CANCEL_MERAKI_FIREWALL_SCHEDULE.ERROR:
          return {
              ...state,
              cancelScheduleErrorMessage: action.payload,
          };
        case GET_MERAKI_FIREWALL_AUTOMATION_RITS.SUCCESS:
          return {
              ...state,
              merakiFirewallAutomationRits: action.payload,
              merakiFirewallAutomationRitsErrorMessage: null,
          };
        case GET_MERAKI_FIREWALL_AUTOMATION_RITS.ERROR:
          return {
              ...state,
              merakiFirewallAutomationRits: [],
              merakiFirewallAutomationRitsErrorMessage: action.payload,
          };
        case POST_MERAKI_FIREWALL_FILE_COMPARE.SUCCESS:
          return {
              ...state,
              merakiFirewallFileCompareSuccess: action.payload,
              merakiFirewallFileCompareErrorMessage: null,
          };
        case POST_MERAKI_FIREWALL_FILE_COMPARE.ERROR:
          return {
              ...state,
              merakiFirewallFileCompareSuccess: null,
              merakiFirewallFileCompareErrorMessage: action.payload,
          };
        case GET_MERAKI_FIREWALL_FILE_COMPARE_LOGS.SUCCESS:
          return {
            ...state,
            merakiFirewallFileCompareLogs: action.payload,
            merakiFirewallFileCompareLogsErrorMessage: null,

          }
        case GET_MERAKI_FIREWALL_FILE_COMPARE_LOGS.ERROR:
          return {
            ...state,
            merakiFirewallFileCompareLogs: [],
            merakiFirewallFileCompareLogsErrorMessage: action.payload,
          }
        default:
          return { ...state };
        }
};

export default merakiFirewallAutomationReducer;