import { GET_ALL_SNOW_APPS, GET_ALL_SNOW_WRAPPER_LOGS } from './actionTypes';

const initialState = {
    snowAppList: [],
    snowAppListErrorMessage: null,
    snowWrapperLogs: [],
    snowWrapperLogsErrorMessage: null
}

const snowWrapperReducer = (state=initialState, action={type:''}) => {
    switch(action.type) {
        case GET_ALL_SNOW_APPS.SUCCESS:
            return {
                ...state, snowAppList: action.payload, snowAppListErrorMessage: null
            }
        case GET_ALL_SNOW_APPS.ERROR:
            return {
                ...state, snowAppList: [], snowAppListErrorMessage: action.payload
            }
        case GET_ALL_SNOW_WRAPPER_LOGS.SUCCESS:
            let { appIncident, totalRecords } = action.payload;
            return {
                ...state,
                 snowWrapperLogs: appIncident, 
                 totalRecords:totalRecords,
                 snowWrapperLogsErrorMessage: null
            }
        case GET_ALL_SNOW_WRAPPER_LOGS.ERROR:
            return {
                ...state, snowWrapperLogs: [], totalRecords:0,snowWrapperLogsErrorMessage: action.payload
            }
        default:
            return {...state}
    }
}

export default snowWrapperReducer;