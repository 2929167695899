import Asset from "../components/asset/assetList/AssetList";
import DeviceManagement from "../components/device-management/DeviceManagement";
import DeviceOnboarding from "../components/device-onboarding/DeviceOnboarding";
import CommandAndControl from "../components/command-and-control/CommandAndControl";
import ShellyReplacement from "../components/shelly-replacement/ShellyReplacement";
import AcuviewMonitoring from "../components/acuview-ipad-monitoring/AcuviewMonitoring";

import PingProbeInventory from "../components/pingprobe/inventory/PingProbeInventory";
import PingProbeIncidentSuppression from "../components/pingprobe/incident-suppression/PingProbeIncidentSuppression";
import PingProbeSuppressionLogs from "../components/pingprobe/suppression-logs/PingProbeSuppressionLogs";
import LivePing from "../components/pingprobe/LivePing"

import IncidentConfig from "../components/incident-config/IncidentConfig";

import SnowWrapper from "../components/snow-wrapper/SnowWrapper";

import AutomationLogs from "../components/rpa/AutomationLogs";
import CommandCenterAutomation from "../components/command-center-automation/CommandCenterAutomation";
import EpeLogs from "../components/epe/EpeLogs";

import NetworkAutomation from "../components/network-automation/NetworkAutomation";
import ServiceMainLogs from "../components/service-main/ServiceMainLogs"

import CPUsageTracker from "../components/cp-usage-tracker/billing-automation/CPUsageTracker";
import CPDeployment from "../components/cp-usage-tracker/CPDeployment";

import ShellyFirmware from "../components/shelly-firmware/ShellyFirmware"

import GARemoteCommand from "../components/ares/GARemoteCommand";

import GAAzureVmAlloy from "../components/ares/GAAzureVmAlloy";

import IncidentSuppressionRequest from "../components/incident-suppression/incident-suppression-request/IncidentSuppressionRequest";
import IncidentSuppressionLogs from "../components/incident-suppression/incident-suppression-logs/IncidentSuppressionLogs";

import { ReactComponent as DeviceManagementIcon } from "../images/device-management-logo-blue.svg";
import { ReactComponent as PingProbeIcon } from "../images/pingprobe-logo-blue.svg";
import { ReactComponent as IncidentConfigIcon } from "../images/inc-config-logo-blue.svg";
import { ReactComponent as SnowWrapperIcon } from "../images/snow-wrapper-logo-blue.svg";
import { ReactComponent as AutomationLogsIcon } from "../images/automation-logo-blue.svg";
import { ReactComponent as CradlePointIcon } from "../images/cp-logo-blue.svg";
import { ReactComponent as ShellyFirmwareIcon } from "../images/shelly-firmware-logo-blue.svg"
import { ReactComponent as GrafanaAgentRemoteCommandIcon } from "../images/remote-command-blue.svg"
import { ReactComponent as IncidentSuppressionIcon } from "../images/incident-suppression-blue.svg"


import { ReactComponent as DeviceManagementActiveIcon } from "../images/device-management-logo-white.svg";
import { ReactComponent as PingProbeActiveIcon } from "../images/pingprobe-logo-white.svg";
import { ReactComponent as IncidentConfigActiveIcon } from "../images/inc-config-logo-white.svg";
import { ReactComponent as SnowWrapperActiveIcon } from "../images/snow-wrapper-logo-white.svg";
import { ReactComponent as AutomationLogsActiveIcon } from "../images/automation-logo-white.svg";
import { ReactComponent as CradlePointActiveIcon } from "../images/cp-logo-white.svg";
import { ReactComponent as ShellyFirmwareActiveIcon } from "../images/shelly-firmware-logo-white.svg"
import { ReactComponent as GrafanaAgentRemoteCommandActiveIcon } from "../images/remote-command-white.svg"
import { ReactComponent as IncidentSuppressionActiveIcon } from "../images/incident-suppression-white.svg"


export const tabList = [
    {
        key: "1",
        header: "Device Management",
        path: "/admin/device-management",
        component: <></>,
        icon: <DeviceManagementIcon />,
        activeIcon: <DeviceManagementActiveIcon />,
        roles: [
            "AssetManagement.Read",
            "DeviceManagement.Read",
            "DeviceManagement.Write",
            "CommandControl.Read",
            "CommandControl.Write",
            "ShellyReplacement.Read",
            "ShellyReplacement.Write",
        ],
        child: [
            {
                key: "1.1",
                header: "Assets",
                path: "/admin/device-management/assets",
                component: Asset,
                icon: "",
                roles: ["AssetManagement.Read"],
                child: [],
            },
            {
                key: "1.2",
                header: "Devices",
                path: "/admin/device-management/devices",
                component: DeviceManagement,
                icon: "",
                roles: ["DeviceManagement.Read"],
                child: [],
            },
            {
                key: "1.3",
                header: "Onboarding",
                path: "/admin/device-management/onboarding",
                component: DeviceOnboarding,
                icon: "",
                roles: ["DeviceManagement.Write"],
                child: [],
            },
            {
                key: "1.4",
                header: "Command and Control",
                path: "/admin/device-management/command-and-control",
                component: CommandAndControl,
                icon: "",
                roles: ["CommandControl.Read", "CommandControl.Write"],
                child: [],
            },
            {
                key: "1.5",
                header: "Shelly Replacement",
                path: "/admin/device-management/shelly-replacement",
                component: ShellyReplacement,
                icon: "",
                roles: ["ShellyReplacement.Read", "ShellyReplacement.Write"],
                child: [],
            },
            {
                key: "1.6",
                header: "Acuview iPad Monitoring",
                path: "/admin/device-management/acuview-ipad-monitoring",
                component: AcuviewMonitoring,
                icon: "",
                roles: ["AssetManagement.Read"],
                child: [],
            },
        ],
    },
    {
        key: "2",
        header: "Ping Probe",
        path: "/admin/ping-probe",
        component: <></>,
        icon: <PingProbeIcon />,
        activeIcon: <PingProbeActiveIcon />,
        roles: ["PingProbe.Read", "PingProbe.Write"],
        child: [
            {
                key: "2.1",
                header: "Inventory",
                path: "/admin/ping-probe/inventory",
                component: PingProbeInventory,
                icon: "",
                roles: ["PingProbe.Read", "PingProbe.Write"],
                child: [],
            },
            {
                key: "2.2",
                header: "Suppression Rule",
                path: "/admin/ping-probe/suppression-rule",
                component: PingProbeIncidentSuppression,
                icon: "",
                roles: ["PingProbe.Write"],
                child: [],
            },
            {
                key: "2.3",
                header: "Suppression Logs",
                path: "/admin/ping-probe/suppression-logs",
                component: PingProbeSuppressionLogs,
                icon: "",
                roles: ["PingProbe.Write"],
                child: [],
            },          
            {
                key: "2.4",
                header: "Live Ping",
                path: "/admin/ping-probe/live-ping",
                component: LivePing,
                icon: "",
                roles: ["PingProbe.Read", "PingProbe.Write"],
                child: [],
            },
        ],
    },
    {
        key: "3",
        header: "Incident Configuration",
        path: "/admin/incident-configuration",
        component: IncidentConfig,
        icon: <IncidentConfigIcon />,
        activeIcon: <IncidentConfigActiveIcon />,
        roles: ["IncidentExclusion.Read", "IncidentExclusion.Write"],
        child: [],
    },
    {
        key: "4",
        header: "SNOW Wrapper",
        path: "/admin/snow-wrapper",
        component: SnowWrapper,
        icon: <SnowWrapperIcon />,
        activeIcon: <SnowWrapperActiveIcon />,
        roles: ["SnowWrapper.Read", "SnowWrapper.Write"],
        child: [],
    },
    {
        key: "5",
        header: "Automation Logs",
        path: "/admin/automation-logs",
        component: <></>,
        icon: <AutomationLogsIcon />,
        activeIcon: <AutomationLogsActiveIcon />,
        roles: ["RPA.Read", "RPA.Write", "CCLogs.Read", "CCLogs.Write", "EPE.Read","NetworkAutomation.Read", "NetworkAutomation.Write","ServiceMain.Read"],
        child: [
            {
                key: "5.1",
                header: "Voice Automation",
                path: "/admin/automation-logs/voice-automation",
                component: AutomationLogs,
                icon: "",
                roles: ["RPA.Read", "RPA.Write"],
                child: [],
            },
            {
                key: "5.2",
                header: "Command Center Automation",
                path: "/admin/automation-logs/command-center-automation",
                component: CommandCenterAutomation,
                icon: "",
                roles: ["CCLogs.Read", "CCLogs.Write"],
                child: [],
            },
            {
                key: "5.3",
                header: "EPE Automation",
                path: "/admin/automation-logs/epe-automation",
                component: EpeLogs,
                icon: "",
                roles: ["EPE.Read"],
                child: [],
            },
            {
                key: "5.4",
                header: "Network Automation",
                path: "/admin/automation-logs/network-automation",
                component: NetworkAutomation,
                icon: "",
                roles: ["NetworkAutomation.Read", "NetworkAutomation.Write"],
                child:[],
            },
            {
                key: "5.5",
                header: "Service Main",
                path: "/admin/automation-logs/service-main",
                component: ServiceMainLogs,
                icon: "",
                roles: ["ServiceMain.Read"],
                child: [],
            },
        ],
    },
    {
        key: "6",
        header: "Cradlepoint",
        path: "/admin/cradlepoint",
        component: <></>,
        icon: <CradlePointIcon />,
        activeIcon: <CradlePointActiveIcon />,
        roles: ["CpBilling.Write", "NcmUpdate.Read", "NcmUpdate.Write"],
        child: [
            {
                key: "6.1",
                header: "Billing Automation",
                path: "/admin/cradlepoint/billing-automation",
                component: CPUsageTracker,
                icon: "",
                roles: ["CpBilling.Write"],
                child: [],
            },
            {
                key: "6.2",
                header: "Deployment",
                path: "/admin/cradlepoint/deployment",
                component: CPDeployment,
                icon: "",
                roles: ["NcmUpdate.Read", "NcmUpdate.Write"],
                child: [],
            },
        ],
    },
    {
        key: "7",
        header: "OTA - Firmware",
        path: "/admin/ota-firmware",
        component: ShellyFirmware,
        icon: <ShellyFirmwareIcon />,
        activeIcon: <ShellyFirmwareActiveIcon />,
        roles: ["NetworkAutomation.Read", "NetworkAutomation.Write"],
        child: [],
    },
 
     {
        key: "8",
        header: "Grafana Agent",
        path: "/admin/grafana-agent",
        component: <></>,          
        icon: <GrafanaAgentRemoteCommandIcon />,
        activeIcon: <GrafanaAgentRemoteCommandActiveIcon />,
        roles: ["GARemoteCommand.Read","GARemoteCommand.Write"],
        child: [
            {
                key: "8.1",
                header: "Grafana Server",
                path: "/admin/grafana-agent/server",
                component: GARemoteCommand,
                icon: "",
                roles: ["GARemoteCommand.Read","GARemoteCommand.Write"],
                child: [],
            },
            {
                key: "8.2",
                header: "Grafana Azure Standard VM Alloy",
                path: "/admin/grafana-agent/azure-vm-alloy",
                component: GAAzureVmAlloy,
                icon: "",
                roles: ["GARemoteCommand.Read","GARemoteCommand.Write"],
                child: [],
            },

        ],
    },



    {
        key: "9",
        header: "EIOT Incident Suppression",
        path: "/admin/incident-suppression",
        component: <></>,
        icon: <IncidentSuppressionIcon />,
        activeIcon: <IncidentSuppressionActiveIcon />,
        roles: ["IncidentSuppression.Read", "IncidentSuppression.Write"],
        child: [
            {
                key: "9.1",
                header: "Suppression Request",
                path: "/admin/incident-suppression/suppression-request",
                component: IncidentSuppressionRequest,
                icon: "",
                roles: ["IncidentSuppression.Write"],
                child: [],
            },
            {
                key: "9.2",
                header: "Suppression Logs",
                path: "/admin/incident-suppression/suppression-logs",
                component: IncidentSuppressionLogs,
                icon: "",
                roles: ["IncidentSuppression.Read"],
                child: [],
            },
        ],
    },
];
