import React from 'react';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';
import fileUpload from "../../../images/file-upload.svg";

const FileInput = ({ fileTypeLabel, fileLabel, setDisplayDialog, fileRef, fileType, fileTypes, file, onFileSelect, onRITSelect, onFileClear, onFileTypeChange, options, placeholder, error }) => {

    const handleFileSelect = (event) => {
        const file = event.files[0];
        const MAX_FILE_SIZE = 2 * 1024 * 1024;
        if (file.name.toLowerCase().endsWith(".csv") && file.size <= MAX_FILE_SIZE) {
            onFileSelect(file);
        } else {
            fileRef.current.clear();
            onFileSelect(null, true);
        }
    };

    return (
        <div className="file-input">
            <div className="row input-field">
                <div className="col-12 col-lg-4">
                    <label>{fileTypeLabel}</label>
                </div>
                <div className="col-12 col-lg-8">
                    {fileTypes.map(({ label, value }) => (
                        <span key={label} style={{ display: "flex", alignItems: "center" }}>
                            <RadioButton
                                name="fileType"
                                value={value}
                                onChange={onFileTypeChange}
                                checked={fileType === value}
                            />
                            <span> {label} </span>
                        </span>
                    ))}
                </div>
            </div>
            <div className="row input-field">
                <div className="col-12 col-lg-4">
                    <label>{fileLabel}</label>
                </div>
                <div className="col-12 col-lg-8">
                    <span>
                    {fileType === "rit" ? (
                        <Dropdown filter options={options} value={file} onChange={onRITSelect} placeholder={placeholder} />
                    ) : (
                        <FileUpload
                            ref={fileRef}
                            className="custom-file-upload"
                            onSelect={handleFileSelect}
                            customUpload={true}
                            uploadHandler={() => setDisplayDialog(true)}
                            onClear={onFileClear}
                            onRemove={onFileClear}
                            accept=".csv"
                            emptyTemplate={<div><span className="or-text">or</span><p className="p-m-0"><img src={fileUpload} alt="" /> Drag and Drop CSV File</p></div>}
                        />
                    )}
                    <div/>
                    {error && (fileType === "file" ? <span className="p-error">You can only upload CSV file with maximum size of 2MB</span>: <span className="p-error">Please select RIT</span>)}
                    </span>
                </div>
            </div>
        </div>
    );
};

FileInput.propTypes = {
    fileType: PropTypes.string.isRequired,
    fileTypes: PropTypes.array.isRequired,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onFileSelect: PropTypes.func.isRequired,
    onFileClear: PropTypes.func.isRequired,
    onFileTypeChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    error: PropTypes.bool
};

export default FileInput;