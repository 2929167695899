import { combineReducers } from 'redux';

import assetReducer from '../components/asset/redux/assetReducer';
import deviceManagementReducer from '../components/device-management/redux/deviceManagementReducer';
import deviceOnboardingReducer from '../components/device-onboarding/redux/deviceOnboardingReducer';
import eventReducer from '../components/device-management/events/redux/eventReducer';
import cpUsageReducer from '../components/cp-usage-tracker/redux/cpUsageReducer';
import searchStoreInputReducer from '../components/search-store/redux/searchStoreInputReducer';
import incidentConfigReducer from '../components/incident-config/redux/incidentConfigReducer';
import commandAndControlReducer from '../components/command-and-control/redux/commandAndControlReducer';
import snowWrapperReducer from '../components/snow-wrapper/redux/snowWrapperReducer';
import rpaReducer from '../components/rpa/redux/rpaReducer';
import shellyReplacementReducer from '../components/shelly-replacement/redux/shellyReplacementReducer';
import commandCenterReducer from '../components/command-center-automation/redux/commandCenterReducer';
import merakiFirewallAutomationReducer from '../components/network-automation/redux/merakiFirewallAutomationReducer';
import pingprobeReducer from '../components/pingprobe/redux/pingprobeReducer';
import loaderReducer from '../common/components/loader/loaderReducer';
import messageReducer from '../components/device-management/messages/redux/messageReducer';
import toastReducer from  '../common/components/Toast/toastReducer';
import profileReducer from '../common/components/profile/profileReducer';
import divisionDropdownReducer from '../common/components/division-dropdown/redux/divisionDropdownReducer';
import epeLogsReducer from '../components/epe/redux/epeLogsReducer';
import serviceMainReducer from "../components/service-main/redux/serviceMainReducer";
import shellyFirmwareReducer from '../components/shelly-firmware/redux/shellyFirmwareReducer';
import gaRemoteCommandReducer from '../components/ares/redux/gaRemoteCommandReducer';
import gaAzureVmAlloyReducer from '../components/ares/redux/gaAzureVmAlloyReducer';
import pendingJobsReducer from '../components/device-management/pending-jobs/redux/pendingJobsReducer';
import incidentSuppressionReducer from '../components/incident-suppression/redux/incidentSuppressionReducer';


/**
 * Combine all reducing function to a single Reducer 
 */
const rootReducer = combineReducers({
    asset: assetReducer,
    deviceManagement: deviceManagementReducer,
    deviceOnboarding: deviceOnboardingReducer,
    event: eventReducer,
    cpUsage: cpUsageReducer,
    searchStore: searchStoreInputReducer,
    incidentConfig: incidentConfigReducer,
    commandAndControl: commandAndControlReducer,
    snowWrapper: snowWrapperReducer,
    rpa: rpaReducer,
    merakiFirewall: merakiFirewallAutomationReducer,
    shellyReplacement: shellyReplacementReducer,
    loader: loaderReducer,
    message: messageReducer,
    toast:toastReducer,
    profile: profileReducer,
    divisionDropdown: divisionDropdownReducer,
    commandCenter: commandCenterReducer,
    pingprobe: pingprobeReducer,
    epe: epeLogsReducer,
    serviceMain: serviceMainReducer,
    shellyFirmware: shellyFirmwareReducer,
    gaRemoteCommand: gaRemoteCommandReducer,
    gaAzureVmAlloy: gaAzureVmAlloyReducer,
    pendingJobs: pendingJobsReducer,
    incidentSuppression: incidentSuppressionReducer
});

export default rootReducer;