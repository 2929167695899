import React, { useState, useEffect } from "react";
import useIdle from "../../hooks/useIdleTimer.js";
import "./SessionModal.scss"
import { handleLogout } from "../../Utils/msalUtils";

function SessionModal() {


  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const handleIdle = () => {
    setRemainingTime(30); //set 30 seconds as time remaining
    setShowModal(true); //show modal
    
  };

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 30});
  useEffect(() => {
    let interval;
    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
        );
      }, 1000);      
    }
    

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      setShowModal(false);
      handleLogout();
    }
  }, [remainingTime, showModal]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything

  const handleStayLoggedIn = () => {
    setShowModal(false);
  };

  function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <>
      {/* handle isIdle for the modal */}
      {isIdle && showModal && (
        <div className="modalpop">
          <div className="modalpop-content">
            <h2>Idle Timeout Warning</h2>
            <p>You are about to be logged out due to inactivity.</p>
            <br />
            Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
            <br />
            <div className="row">
            <button className="btn btn-danger" onClick={handleLogout}>
              Logout
            </button>
            <button className="btn btn-primary " onClick={handleStayLoggedIn}>
              Stay Logged In
            </button>
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default SessionModal;